<template>
  <section class="view-workflow-document" v-loading="loading">
    <div class="template-grand-parent">
      <div class="template-container-wrapper-parent">
        <el-scrollbar ref="scrollbar">
         
          <div class="document-root builder-default">
            <div   class="form-builder" ref="formBuilder">
              <div class="form-holder">
                <div class="form-image-holder">
                  
                  <div  id="wrapper" :style="isEditorCkContentReady ? 'ml-1 mr-1' : 'text-align: center'">
                    <div
                      class="background-div  mr-2"
                      v-if="isEditorCkContentReady"
                      style="width: 950px;height:100%"
                      v-html="editorContentInPreviewPage"
                    ></div>
                   <div v-if="!isEditorCkContentReady">
                    <div
                      v-for="(doc, j) in getAllPdfData"
                      :key="j"
                      :id="`wrapper_${j}`"
                    >
                    
                      <canvas
                        v-for="i of doc.pages"
                        :key="i"
                        :id="`${j}_canvas_${i}`"
                        tabindex="0"
                      ></canvas>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="form-fields-holder">
                  <div
                    v-for="(item, index) of this.elements"
                    :key="index"
                    :data-index="index"
                  >
                    <!-- displaying field filling user name -->
                    <el-tooltip
                      class="tooltip"
                      effect="dark"
                      :content="
                        item && item.userData && item.userData.user
                          ? item.userData.user
                          : item.key
                          ? item.key
                          : item.label
                      "
                      placement="top-start"
                    >
                      <!-- Heading Preview -->
                      <p
                        :style="getFieldStyles(item)"
                        v-if="
                          item.type == 'PRIMARY_FIELDS' && item.initial_value
                        "
                      >
                        {{ item.content }}
                      </p>
                      <p
                        :style="getFieldStyles(item)"
                        v-else-if="item.type == 'HEADING'"
                      >
                        {{ item.content }}
                      </p>

                      <!-- Paragraph Preview -->
                      <p
                        :style="getFieldStyles(item)"
                        v-else-if="item.type == 'PARAGRAPH'"
                      >
                        {{ item.content }}
                      </p>
                      <img
                        v-else-if="item.type == 'MY_INITIAL'"
                        :src="item.value"
                        :style="getStyle(item)"
                      />
                      <img
                        v-else-if="item.type == 'my-signature'"
                        :src="item.source"
                        :style="getStyle(item)"
                      />
                      <img
                        v-else-if="item.type == 'IMAGE' && item.value.length"
                        :src="item.value"
                        :style="getStyle(item)"
                      />

                      <el-input
                        :style="getFieldStyles(item)"
                        type="text"
                        :placeholder="item.placeholder"
                        v-else-if="
                          item.type == 'SINGLE_LINE_TEXT' && !item.is_use_boxes
                        "
                        v-model="item.value"
                      >
                      </el-input>

                      <el-input
                        :style="getFieldStyles(item)"
                        type="text"
                        :placeholder="item.placeholder"
                        v-else-if="item.type == 'NUMBER' && !item.is_use_boxes"
                        v-model="item.value"
                      ></el-input>
                      <div
                        v-else-if="item.type == 'DATE' && !item.is_use_boxes"
                        :style="getDateFieldStyles(item)"
                      >
                        <el-date-picker
                          :format="
                            getDefaultDateFormat &&
                            getDefaultDateFormat.includes('Do') == false
                              ? getDefaultDateFormat
                                  .replace('YYYY', 'yyyy')
                                  .replace('DD', 'dd')
                              : 'MM-dd-yyyy'
                          "
                          :placeholder="item.placeholder"
                          v-model="item.value"
                        ></el-date-picker>
                      </div>
                      <div
                        v-else-if="item.type == 'TIME_RANGE'"
                        :style="getFieldStyles(item, index)"
                      >
                        <el-time-picker
                          type="timerange"
                          :placeholder="
                            item.placeholder
                              ? item.placeholder
                              : 'Select date range'
                          "
                          prefix-icon=""
                          v-bind:class="{
                            'time-picker-item': true,
                            'smaller-than': true,
                          }"
                          is-range
                          v-model="item.value"
                          range-separator="To"
                          start-placeholder="Start time"
                          end-placeholder="End time"
                          popper-class="my-popover"
                          style="white-space: nowrap"
                        ></el-time-picker>
                      </div>
                      <div
                        v-else-if="
                          item.type == 'FIXED_TIME' ||
                          (item.type == 'TIME' &&
                            item.is_fixed &&
                            item.field_type == 'ENTITY_FIELD')
                        "
                        :style="getFieldStyles(item)"
                      >
                        <el-time-select
                          :picker-options="getTimeProperties"
                          :placeholder="
                            item.placeholder
                              ? item.placeholder
                              : 'Select Fixed Time'
                          "
                          v-model="item.value"
                          prefix-icon=""
                          v-bind:class="{
                            'time-picker-item': true,
                            'smaller-than': true,
                          }"
                          format="HH:mm:ss"
                        >
                        </el-time-select>
                      </div>
                      <!-- <div
                        v-else-if="item.type == 'FIXED_TIME'"
                        :style="getDateFieldStyles(item)"
                      >
                        <el-time-select
                          :picker-options="getTimeProperties(item)"
                          :format="'HH:mm:ss'"
                          :placeholder="item.placeholder"
                          v-model="item.value"
                          :ref="removeHashesFromKey(item.key)"
                        ></el-time-select>
                      </div> -->
                      <el-select
                        :height="item.height"
                        :width="item.width"
                        :style="getDateFieldStyles(item)"
                        collapse-tags
                        v-else-if="item.type == 'WEEKDAYS'"
                        v-model="item.value"
                        :placeholder="item.placeholder"
                        multiple
                        :ref="removeHashesFromKey(item.key)"
                      >
                        <el-option
                          v-for="(option, index) of weekdays_options"
                          :value="option.value"
                          :label="option.name"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <div
                        v-else-if="
                          (item.type === 'SINGLE_LINE_TEXT' ||
                            item.type === 'NUMBER') &&
                          item.is_use_boxes
                        "
                        :style="getFieldStyles(item)"
                      >
                        <div class="input-container">
                          <div
                            v-for="(box, index) in item.boxes"
                            :key="index"
                            class="box"
                            :class="{ 'small-box': box.value === '' }"
                            ref="boxElements"
                            :style="{
                              width: item.boxWidth + 'px',
                              height: item.height + 'px',
                            }"
                          >
                            <input
                              v-model="item.boxes[index]"
                              @input="handleInputChange(item, index)"
                              @keydown.backspace="handleBackspace(index)"
                              maxlength="1"
                              type="text"
                              class="box-input"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-else-if="item.type == 'RADIO_BUTTON_GROUP'"
                        :style="getFieldStyles(item)"
                      >
                        <!-- {{filesData}} -->
                        <RadioExecute
                          :data="item"
                          :form="documentData"
                          :is-view="false"
                          :isFromDocument="true"
                          :allFields="elements"
                        ></RadioExecute>
                      </div>
                      <div
                        v-else-if="
                          item.type == 'SINGLE_LINE_CONTENT' &&
                          item.field_type == 'ENTITY_FIELD' &&
                          item.content
                        "
                      >
                        <SingleLineContentExecute
                          :data="item"
                          :form="documentData"
                          :is-view="false"
                          :isFromDocument="true"
                          :allFields="elements"
                        >
                        </SingleLineContentExecute>
                      </div>
                      <div
                        v-else-if="
                          item.type == 'SINGLE_LINE_CONTENT' &&
                          item.field_type != 'ENTITY_FIELD' &&
                          item.content
                        "
                      >
                        <el-input
                          type="textarea"
                          class="field-textarea"
                          v-model="item.content"
                          :style="
                            getFieldStyles(
                              item,
                              'width: 100% !important; height: 100% !important;'
                            )
                          "
                          @focus="handleTextAreaFocus"
                          @blur="handleTextAreaBlur"
                          :placeholder="item.content"
                        ></el-input>
                        <div
                          class="overlay"
                          :style="getTextColorByFilledBy(item)"
                        >
                          {{ item.content == "" ? item.placeholder : "" }}
                        </div>
                      </div>
                      <div
                        v-else-if="item.type == 'DATE' && item.is_use_boxes"
                        :style="getFieldStyles(item)"
                      >
                        <div class="input-container">
                          <div
                            v-for="(box, index) in item.boxes"
                            :key="index"
                            class="box"
                            :class="{ 'small-box': box.value === '' }"
                            ref="boxElements"
                            :style="{
                              width: item.boxWidth + 'px',
                              height: item.height + 'px',
                            }"
                          >
                            <input
                              v-model="item.boxes[index]"
                              @input="handleInputChange(item, index)"
                              @keydown.backspace="handleBackspace(index)"
                              maxlength="1"
                              type="text"
                              class="box-input"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-else-if="item.type == 'DATE_TIME_RANGE'"
                        :style="getFieldStyles(item, index)"
                      >
                        <el-date-picker
                          type="datetimerange"
                          :placeholder="
                            item.placeholder
                              ? item.placeholder
                              : 'Select date time range'
                          "
                          v-model="item.value"
                          prefix-icon=""
                          v-bind:class="{
                            'date-time-picker-item': true,
                            'smaller-than': true,
                          }"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          popper-class="my-popover"
                          :format="'MM-dd-yyyy HH:mm:ss'"
                          style="white-space: nowrap"
                        ></el-date-picker>
                      </div>
                      <div
                        v-else-if="item.type == 'DATE_RANGE'"
                        :style="getFieldStyles(item, index)"
                      >
                        <el-date-picker
                          type="daterange"
                          :placeholder="
                            item.placeholder
                              ? item.placeholder
                              : 'Select date range'
                          "
                          v-model="item.value"
                          prefix-icon=""
                          v-bind:class="{
                            'date-picker-item': true,
                            'smaller-than': true,
                          }"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          popper-class="my-popover"
                          :format="'MM-dd-yyyy '"
                          style="white-space: nowrap"
                        ></el-date-picker>
                      </div>
                      <div
                        v-else-if="item.type == 'TIME' && !item.is_fixed"
                        :style="getFieldStyles(item)"
                      >
                        <el-time-picker
                          type="time"
                          :placeholder="
                            item.placeholder ? item.placeholder : 'Select Time'
                          "
                          v-model="item.value"
                          prefix-icon=""
                          v-bind:class="{
                            'time-picker-item': true,
                            'smaller-than': true,
                          }"
                          format="HH:mm:ss"
                        >
                        </el-time-picker>
                      </div>
                      <div
                        v-else-if="item.type == 'DATE_TIME'"
                        :style="getFieldStyles(item)"
                      >
                        <el-date-picker
                          type="datetime"
                          :placeholder="
                            item.placeholder
                              ? item.placeholder
                              : 'Select date and time'
                          "
                          v-model="item.value"
                          prefix-icon=""
                          v-bind:class="{
                            'date-time-picker-item': true,
                            'smaller-than': true,
                          }"
                          format="MM-dd-yyyy HH:mm:ss"
                        ></el-date-picker>
                      </div>
                      <!-- <p v-else-if="item.type == 'DATE_SIGNED'">oksdf{{item.value}}</p> -->
                      <el-date-picker
                        :height="item.height"
                        :width="item.width"
                        :style="getDateFieldStyles(item)"
                        type="date"
                        :placeholder="item.placeholder"
                        v-else-if="item.type == 'DATE_SIGNED'"
                        v-model="item.value"
                        disabled
                      ></el-date-picker>

                      <el-select
                        :height="item.height"
                        :width="item.width"
                        :style="getFieldStyles(item)"
                        v-else-if="item.type == 'SELECT'"
                        v-model="item.value"
                        :placeholder="item.placeholder"
                      >
                        <el-option
                          v-for="(option, index) of item.options"
                          :key="index"
                          :value="option"
                          :label="option"
                        ></el-option>
                      </el-select>

                      <el-select
                        :height="item.height"
                        :width="item.width"
                        :style="getDateFieldStyles(item)"
                        collapse-tags
                        v-else-if="item.type == 'MULTI_SELECT'"
                        v-model="item.value"
                        :placeholder="item.placeholder"
                        multiple
                      >
                        <el-option
                          v-for="(option, index) of item.options"
                          :key="index"
                          :value="option"
                          :label="option"
                        ></el-option>
                      </el-select>
                      <el-select
                        :height="item.height"
                        :width="item.width"
                        :style="getFieldStyles(item)"
                        v-else-if="item.type == 'LIST'"
                        v-model="item.value"
                        :placeholder="item.placeholder"
                      >
                        <el-option
                          v-for="option of item.list_data"
                          :key="option.value"
                          :value="option.value"
                          :label="option.name"
                        ></el-option>
                      </el-select>
                      <div
                        v-else-if="
                          item.type == 'CHECKBOX' && item.is_use_cross_mark
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-checkbox"
                          :height="item.height"
                          :width="item.width"
                          @input="handleCheckBoxChange(item, index)"
                          :style="getStyle(item)"
                          v-model="item.value"
                        />
                        <span
                          v-if="getCheckBoxLabel(item)"
                          :style="getFieldStyles(item)"
                          >{{ item.label }}</span
                        >
                      </div>
                      <div
                        v-else-if="
                          item.type == 'CHECKBOX' && !item.is_use_cross_mark
                        "
                      >
                        <input
                          type="checkbox"
                          :height="item.height"
                          :width="item.width"
                          @input="handleCheckBoxChange(item, index)"
                          :style="getStyle(item)"
                          v-model="item.value"
                        />
                        <span
                          v-if="getCheckBoxLabel(item)"
                          :style="getFieldStyles(item)"
                          >{{ item.label }}</span
                        >
                      </div>
                      <div
                        v-else-if="item.type == 'YES_OR_NO'"
                        :style="getStyle(item)"
                      >
                        <el-radio v-model="item.value" label="YES"
                          >YES</el-radio
                        >
                        <el-radio v-model="item.value" label="NO">NO</el-radio>
                      </div>

                      <el-input
                        type="textarea"
                        :rows="item.rows"
                        :style="getFieldStyles(item)"
                        v-else-if="item.type == 'MULTI_LINE_TEXT'"
                        v-model="item.value"
                        :placeholder="item.placeholder"
                      />
                      <button
                        v-else-if="item.type == 'SIGNATURE' && !item.source"
                        :style="getStyle(item)"
                      >
                        {{ item.label }}
                        <i class="el-icon-edit"></i>
                      </button>
                      <button
                        v-else-if="item.type == 'ATTACHMENT'"
                        :style="getStyle(item)"
                        @click="captureAttachmentMethod(item, index)"
                      >
                        {{ item.label }}
                        <!-- <i class="el-icon-paperclip"></i> -->
                      </button>
                      <img
                        v-else-if="item.type == 'SIGNATURE' && item.source"
                        :width="item.width"
                        :height="item.height"
                        :src="item.source"
                        :style="getStyle(item, true)"
                        tabindex="0"
                      />

                      <button
                        v-else-if="item.type == 'INITIAL' && !item.source"
                        :style="getStyle(item)"
                        @click="handleInitialClick(item, index)"
                      >
                        {{ item.label }}
                        <i class="el-icon-edit"></i>
                      </button>
                      <img
                        v-else-if="item.type == 'INITIAL' && item.source"
                        :width="item.width"
                        :height="item.height"
                        :src="item.source"
                        :style="getStyle(item)"
                        tabindex="0"
                      />
                      <!-- @click="captureSignature(item, index)" -->
                      <el-input
                        type="text"
                        :style="getFieldStyles(item)"
                        v-else-if="
                          item.type == 'PRIMARY_FIELDS' &&
                          !item.is_use_boxes &&
                          item.selected_tag !== 'address' &&
                          item.selected_tag !== 'full_name'
                        "
                        v-model="item.content"
                        :placeholder="item.placeholder"
                      />
                      <el-input
                        type="text"
                        :style="getFieldStyles(item)"
                        v-else-if="
                          item.type == 'PRIMARY_FIELDS' &&
                          !item.is_use_boxes &&
                          (item.selected_tag === 'full_name' ||
                            item.selected_tag === 'address')
                        "
                        v-model="item.value"
                        :placeholder="item.placeholder"
                      />
                      <div
                        v-else-if="
                          item.type == 'PRIMARY_FIELDS' &&
                          (item.selected_tag == 'full_name' ||
                            item.selected_tag == 'first_name' ||
                            item.selected_tag == 'last_name') &&
                          item.is_use_boxes
                        "
                      >
                        <div class="input-container">
                          <div
                            v-for="(box, index) in item.boxes"
                            :key="index"
                            class="box"
                            :class="{ 'small-box': box.value === '' }"
                            ref="boxElements"
                            :style="{
                              width: item.boxWidth + 'px',
                              height: item.height + 'px',
                            }"
                          >
                            <input
                              v-model="item.boxes[index]"
                              @input="handleInputChange(item, index)"
                              @keydown.backspace="handleBackspace(item, index)"
                              maxlength="1"
                              type="text"
                              class="box-input"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-else-if="item.type == 'ENTITY'"
                        :style="getFieldStyles(item)"
                      >
                        <ConfigureEntityField
                          :entityId="item.entity_id"
                          :form="item"
                          :isDisable="false"
                          :documentUsers="currentDocumentUsers"
                        >
                        </ConfigureEntityField>
                      </div>
                      <div
                        v-else-if="item.type == 'HORIZONTAL_LINE'"
                        :style="getFieldStyles(item)"
                      >
                        <HorizontalLineExecute
                          :data="item"
                          :form="documentData"
                          :is-view="false"
                          :isFromDocument="true"
                          :allFields="elements"
                        >
                        </HorizontalLineExecute>
                      </div>
                      <div
                        v-else-if="item.type == 'AUTO_INCREMENT_NUMBER'"
                        :style="getFieldStyles(item)"
                      >
                        <AutoIncrementExecute
                          :data="item"
                          :form="documentData"
                          :is-view="false"
                          :isFromDocument="true"
                          :allFields="elements"
                          :entityId="item.entity_id"
                        ></AutoIncrementExecute>
                      </div>
                      <div
                        v-else-if="item.type == 'FORMULA'"
                        :style="getFieldStyles(item)"
                      >
                        <FormulaExecute
                          :data="item"
                          :form="documentData"
                          :is-view="false"
                          :isFromDocument="true"
                          :workflowData="{}"
                          :allFields="elements"
                        >
                        </FormulaExecute>
                      </div>
                      <div
                        v-else-if="item.type == 'FILE'"
                        :style="getFieldStyles(item)"
                      >
                        <!-- {{filesData}} -->
                        <DocumentExecute
                          :data="item"
                          :form="filesData"
                          :is-view="false"
                          :isFromDocument="true"
                        ></DocumentExecute>
                      </div>
                      <div
                        v-else-if="item.type == 'REPEATABLE_PARENT'"
                        :style="getFieldStyles(item)"
                      >
                        <ConfigureRepeatableField
                          :entityId="item.entity_id"
                          :form="item"
                          :isDisable="false"
                        >
                        </ConfigureRepeatableField>
                      </div>
                      <currency-input
                        v-else-if="item.type == 'CURRENCY'"
                        v-model="item.value"
                        :id="item.key"
                        :placeholder="item.placeholder"
                        :style="getFieldStyles(item)"
                        :currency="
                          item.validations && item.validations.currency
                            ? item.validations.currency
                            : 'USD'
                        "
                        :locale="
                          item.validations && item.validations.locale
                            ? item.validations.locale
                            : 'en-US'
                        "
                      />
                      <div :style="getStyle(item)" v-else>
                        <el-table
                          v-if="
                            item.section == 'table' &&
                            item.headers &&
                            item.headers.length &&
                            !tableElementLoading
                          "
                          :data="item.data"
                        >
                          <el-table-column
                            type="index"
                            width="30"
                          ></el-table-column>
                          <el-table-column
                            v-for="(header, index) of item.headers"
                            :key="index"
                          >
                            <template slot="header">{{ header }}</template>
                            <tefield-stats-blockmplate slot-scope="scope">{{
                              item.data[scope.$index][header]
                            }}</tefield-stats-blockmplate>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="document-status">
              <el-row class="mt-1">
                <el-col :span="6" class="ml-1">
                  <h3
                    class="clr-red fw-normal"
                    v-if="currentRenderTemplate && currentRenderTemplate.name"
                  >
                    {{ currentRenderTemplate.name }}
                  </h3>
                </el-col>
                <el-col :offset="11" :span="6">
                  <div style="display: flex">
                    <el-button
                      @click="renderPreviousTemplate"
                      class="px-3"
                      :style="
                        this.templateData &&
                        this.templateData.length > 1 &&
                        isCCuserOrSender
                          ? 'border-radius:2px;margin-left:-250px'
                          : 'border-radius: 2px;margin-left:-150px'
                      "
                      v-if="
                        this.templateData &&
                        this.templateData.length > 1 &&
                        isCCuserOrSender
                          ? 'border-radius:2px;margin-left:-250px'
                          : 'border-radius: 2px'
                      "
                    >
                      Prev</el-button
                    >
                    <el-button
                      @click="renderNextTemplate"
                      style="color: #f754a2; border-radius: 2px"
                      class="px-3"
                      v-if="
                        this.templateData &&
                        this.templateData.length &&
                        this.currentRenderTemplateIndex !=
                          this.templateData.length - 1
                      "
                      >Next</el-button
                    >
                    <!-- <el-button
                        size="mini"
                        class="px-3"
                        :style="
                          this.templateData && this.templateData.length > 1
                            ? 'border-radius:2px;margin-left:10px'
                            : 'border-radius: 2px;margin-left:20px'
                        "
                        v-if="isCCuserOrSender"
                        @click="goToPrevField()"
                        >Prev Field</el-button
                      >
                      <el-button
                        size="mini"
                        style="color: #f754a2; border-radius: 2px"
                        class="px-3"
                        v-if="isCCuserOrSender"
                        @click="goToNextField()"
                        >Next Field</el-button
                      > -->
                    <el-button
                      v-if="
                        this.templateData &&
                        this.templateData.length &&
                        this.currentRenderTemplateIndex ==
                          this.templateData.length - 1
                      "
                      @click="prepareTempData"
                      type="danger"
                      >Send</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <el-dialog
      :destroy-on-close="true"
      :visible.sync="captureSignatureDialog"
      title="Authorize Signature"
      class="outersize-confirm-type-three authorize-signature-popup"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <ESignature
        v-if="captureSignatureDialog"
        :workflowName="workflowName"
        :templateName="documentName"
        :purpose="'COMPANY_DOCUMENT'"
        :isSecureSign="secureEsign"
        v-on:onVerfiedSignature="updateDocumentSignature"
        v-on:cancelESign="captureSignatureDialog = false"
      ></ESignature>
    </el-dialog>

    <el-dialog
      :destroy-on-close="true"
      :visible.sync="captureInitialDialog"
      title="Initial"
      class="dialog-initial"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-if="captureInitialDialog">
        <Initial ref="myInitialPad"></Initial>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveInitial()">Save</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showSavedSignatureData" title="eSignature">
      <div v-loading="esignLoading">
        <el-row>
          <el-col :span="18">
            <table v-if="esignData">
              <tr>
                <td>Signed On</td>
                <td>
                  :: {{ esignData.signed_at | moment("MM-DD-YYYY HH:mm:ss") }}
                </td>
              </tr>
            </table>

            <p>Signature</p>
            <img :src="esignData.signature" height="100" />
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogForSavingData"
      title="Warning"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
    >
      <p style="font-family: inter, sans-serif; font-size: 14px">
        If you go back, the data you have filled in so far will not be saved.
        Would you like to save before leaving?
      </p>
      <span slot="footer">
        <el-button @click="closePopup()">Cancel</el-button>
        <el-button type="primary" @click="saveWorkflowData()"
          >Save & Close</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="
        this.currentIndividualEntity && this.currentIndividualEntity.name
          ? 'Add new ' + this.currentIndividualEntity.name + `'s data`
          : 'Add New Data'
      "
      :visible.sync="quickAddNewEntityData"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-if="currentEntityOfQuickAdd && currentEntityOfQuickAdd.length">
        <el-scrollbar wrap-style="max-height: 600px;">
          <el-form
            :label-position="'top'"
            label-width="20px"
            :model="newEntityForm"
            v-if="quickAddNewEntityData"
          >
            <PrimaryDataForm
              :fieldsListData="currentEntityOfQuickAdd"
              :form="newEntityForm"
              :isFromDocument="true"
              :parentyEntityId="currentIndividualEntity._id"
            >
            </PrimaryDataForm>
          </el-form>
        </el-scrollbar>
        <span slot="footer" class="dialog-footer float-right">
          <el-button @click="handleClose">Cancel</el-button>
          <el-button
            v-if="
              this.currentIndividualEntity &&
              this.currentIndividualEntity.entity_type == 'INDIVIDUAL'
            "
            type="primary"
            :disabled="checkAllfieldsFilled"
            @click="addNewEntityDataQuickAdd"
          >
            Add
          </el-button>
          <el-button
            v-else
            type="primary"
            :disabled="checkAllfieldsFilled"
            @click="addPrimaryDataToEntity('form')"
            >Add</el-button
          >
        </span>
      </div>
    </el-dialog>
    <el-dialog
      title="Add new data"
      :visible.sync="repeatableDataModal"
      width="35%"
      :before-close="handleCloseRepeatable"
    >
      <div v-loading="repeatableDataLoading">
        <ConfigureNewRepeatableDataAdd
          :newRepeatableTemplateData="newRepeatableTemplateData"
          :selectedRow="newRepeatableData"
          :getDefaultDateFormat="getDefaultDateFormat"
        >
        </ConfigureNewRepeatableDataAdd>
        <span slot="footer" class="dialog-footer float-right">
          <el-button @click="handleCloseRepeatable">Cancel</el-button>
          <el-button
            type="primary"
            @click="addNewRepeatableData(currentDocumentUsers)"
            :loading="repeatableDataLoading"
            :disabled="checkPrimaryFieldsFilled"
            >Add</el-button
          >
        </span>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showFinishErrorElementDialog"
      class="outersize-confirm-type-one"
      title="Alert Message!"
      :width="getIsMobile ? '100%' : '70%'"
      :before-close="closeAlertPopup"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p
        style="font-family: inter, sans-serif"
        v-if="
          this.templateData &&
          this.templateData.length &&
          this.currentRenderTemplateIndex != this.templateData.length - 1 &&
          isCCuserOrSender
        "
      >
        Please complete all the required fields to proceed to the next template.
      </p>
      <p v-else style="font-family: inter, sans-serif">
        Please fill the required fields:
      </p>
      <div
        class="alertPopupStyle"
        v-for="(name, index) in this.unfilledFields"
        :key="index"
      >
        {{
          index + 1 + " " + "-" + " " + name.placeholder != "Enter Value"
            ? name.placeholder
            : name.label
        }}
        <br />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAlertPopup()">Close</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
import moment from "moment";
import CurrencyInput from "./../vue-weblineindia-currency-input/src/component";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import NavigationHelper from "@/mixins/navigationHelper";
import { bus } from "../../main";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
var pdfjsLib = window["pdfjs-dist/build/pdf"];
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js";
import EntityHelper from "@/mixins/EntitiesHelper";
import PrimaryDataForm from "@/components/entity/PrimaryDataCollectionForm.vue";
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";

export default {
  name: "workflows-workflowTemplatePreview",
  components: {
    CurrencyInput,
    PrimaryDataForm,
    ConfigureEntityField: () =>
      import(
        "@/components/companyDocuments/configureDocuments/ConfigureEntityField"
      ),
    HorizontalLineExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/HorizontalLineExecute.vue"
      ),
    AutoIncrementExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/AutoIncrementExecute.vue"
      ),
    DocumentExecute: () =>
      import("@/components/templates/formComponentsExecute/FileExecute"),
    FormulaExecute: () =>
      import("@/components/templates/formComponentsExecute/FormulaExecute"),
    ConfigureRepeatableField: () =>
      import(
        "@/components/companyDocuments/configureDocuments/ConfigureRepeatableField"
      ),
    ESignature: () => import("@/components/signature/ESignature"),
    ConfigureNewRepeatableDataAdd: () =>
      import(
        "@/components/companyDocuments/configureDocuments/ConfigureNewRepeatableDataAdd.vue"
      ),
    RadioExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/RadioButtonGroupExecute"
      ),
    SingleLineContentExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/SingleLineContentExecute"
      ),
  },
  props: ["workflowData", "allUsersData", "is_prepare", "dialogForSavingData"],
  async mounted() {
    await this.fetchCompanyDetails(), 
    await this.setEntityData();
    await this.arrangeTemplateData();
    await this.renderNextTemplate();
  },

  computed: {
    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getNewEntityData",
      "getNewEntityCreateError",
      "getPrimaryEntityDataStatus",
      "getPrimaryEntityDataErrors",
      "getEntityDataCreateByTemplateStatus",
    ]),
    ...mapGetters("entities", [
      "getEntityDataByQuery",
      "getCompanyEntityDetails",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getInitialUrl",
      "getUserSignature",
      "getDefaultDateFormat",
      "getActiveWorkspace",
      "getUserType",
      "getIsMobile",
    ]),
    ...mapGetters("templateWorkflow", [
      "getWorkflowSavingData",
      "getCreatedworkflowDataById",
    ]),
    basePdfdownloadUrl() {
      return appConfig.S3_BUCKET_URL;
    },
    getPdfPages() {
      return this.pdfPages;
    },
    getAllPdfData() {
      return this.allPdfData;
    },
    getTotalPdfs() {
      return this.total_documents;
    },

    checkAllfieldsFilled() {
      if (
        Object.keys(this.newEntityForm) &&
        this.currentEntityOfQuickAdd &&
        Object.keys(this.newEntityForm).length >=
          this.currentEntityOfQuickAdd.length
      ) {
        let emptyField = this.currentEntityOfQuickAdd.find(
          (e) =>
            (e.key && this.newEntityForm[e.key] == "") ||
            this.newEntityForm[e.key] == undefined
        );
        if (emptyField) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      editorContentInPreviewPage:"",
      editorCkContent: "",
      isEditorCkContentReady: false,
      customConvertedFields: [],
      checkBoxGroupArray: [],
      allMentionableFields: [],
      allEntities: [],
      allSelectedEntityMentionFields: [],
      contactTypeEntityId: null,
      editorFields: [],
      loading: false,
      currentRenderTemplate: null,
      currentRenderTemplateIndex: -1,
      elements: [],
      dataSavingWhileBack: [],
      matchingTemplate: {},
      showFinishErrorElementDialog: false,
      eSignId: "",
      eSignatureDialogVisible: false,
      showSavedSignatureData: false,
      unfilledFields: [],
      quickAddNewEntityData: false,
      checkBoxGroups: [],
      field_color: {
        filled: "rgb(83, 216, 21)",
        default: "#FF0000",
        highlight: "#f754a2",
        optional: "#61ACF8",
      },
      scale: 1,
      weekdays_options: [
        { name: "Monday", value: "MON" },
        { name: "Tuesday", value: "TUE" },
        { name: "Wednesday", value: "WED" },
        { name: "Thursday", value: "THU" },
        { name: "Friday", value: "FRI" },
        { name: "Saturday", value: "SAT" },
        { name: "Sunday", value: "SUN" },
      ],
      isInitialRender: true,
      captureSignatureDialog: false,
      docPages: 0,
      pdfPages: 1,
      esignLoading: false,
      esignData: {},
      allPdfData: [],
      templateData: [],
      captureInitialDialog: false,
      filesData: {},
      document_url_paths: [],
      total_documents: 1,
      allTemplatesData: {},
      userPrimaryData: {},
      documentData: {},
      currentDocumentUsers: [],
      userEntityData: {},
      repeatableDataModal: false,
      repeatableDataLoading: false,
      newRepeatableTemplateData: [],
      repeatableSelectedField: {},
      newRepeatableData: {},
      repeatableIds: [],
      currentIndividualEntity: [],
      uploadFromOthersFileName: "",
      isCCuserOrSender: false,
      quickEntityForm: {},
      currentEntityOfQuickAdd: [],
      newEntityForm: {},
      currentEntityRelationships: [],
      cKEditorTemplatesUsed: [],
      ckEditorWorkflowUsers: [],
    };
  },
  mixins: [
    EntityHelper,
    entityRelationshipMixin,
    NavigationHelper,
    FormbuilderHelper,
    ComanyDocumentsPresignedURLsHelper,
  ],
  created() {
    this.currentEntityOfQuickAdd = [];
    bus.$on("passingParams", (data) => {
      if (data) {
        console.log("---", data);
        (this.cKEditorTemplatesUsed = data.templateUsed),
          (this.ckEditorWorkflowUsers = data.workflowUsers);
      }
    });
    bus.$on("isPrepare", (data) => {
      if (data.is_prepare === true) {
        this.prepareTempData();
      }
    });
    bus.$on("entityData-updated", (data) => {
      if (data.field && data.field.key) {
        this.updateEntityVariablesData(
          data.entityDataId,
          data.field,
          data.data,
          data.value,
          data.changed
        );
      }
    });
    bus.$on("repeatableData-updated", (data) => {
      if (data.field && data.field.key) {
        this.updateRepeatableVariablesData(
          data.repeatableDataId,
          data.field,
          data.data,
          data.value
        );
      }
    });
    bus.$on("open-quick-entity-add-individual", (data) => {
      this.selectedEntityQuickAddField = data.entityField;
      let newEntityAddedData = [];
      this.currentEntityOfQuickAdd = [];
      this.currentIndividualEntity = [];
      this.currentEntityRelationships = [];
      newEntityAddedData = data.field;
      this.currentEntityOfQuickAdd = newEntityAddedData;
      this.currentIndividualEntity = data.currentEntity;
      this.currentEntityRelationships = data.relationshipsData;
      this.quickAddStandardTemplteIndividualEntity = data.standardTemplate;
      this.quickAddNewEntityData = true;
    });
    bus.$on("open-repeatable-data", (data) => {
      if (data && data.template_data) {
        let primaryFields = data.template_data.primary_fields
          ? data.template_data.primary_fields
              .filter((e) => e && e.key)
              .flatMap((e) => e.key)
          : [];
        this.newRepeatableTemplateData = this.getTemplateFields(
          data.template_data
        );
        this.newRepeatableTemplateData = this.newRepeatableTemplateData.map(
          (e) => {
            if (
              primaryFields &&
              primaryFields.length &&
              primaryFields.indexOf(e.key) != -1
            ) {
              e.is_primary = true;
            }
            return e;
          }
        );
        this.repeatableIds = data.indexKeys;
        this.repeatableSelectedField = data.field;
        this.repeatableDataModal = true;
      }
    });
  },
  methods: {
    closePopup() {
      let closeSaveFieldsModal = this.dialogForSavingData;
      closeSaveFieldsModal = false;
      this.dialogForSavingData = closeSaveFieldsModal;
      this.$router.push({
        name: "Workflows",
      });
    },
    async fetchEntities() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch("entities/fetchEntities", params);
      console.log("this.getAllEntities.", this.getAllEntities);
      this.allEntities = [...this.getAllEntities.data];
    },
    async companyEntityDetails(users) {
      try {
        await this.$store.dispatch("entities/fetchCompanyEntityDetails");

        if (
          this.getCompanyEntityDetails &&
          this.getCompanyEntityDetails.entity_data_details &&
          this.getCompanyEntityDetails.entity_data_details[0] &&
          users.length
        ) {
          users.forEach((e) => {
            if (e.type == "SENDER") {
              this.$set(
                e,
                "entity_data_id",
                this.getCompanyEntityDetails.entity_data_details[0]._id
              );
              this.$set(
                e,
                "contact_type",
                this.getCompanyEntityDetails.entity_data_details[0].entity_id
              );
            }
          });
        }
      } catch (err) {
        console.log(err);
      }

      return users;
    },
    async replaceVariables(editorContent, ckEditorFields, template, users) {
      this.loading = true;
      this.loadingText =
        "Mapping mention fields with user entity fields data...";
      let modifiedContents = [];
      this.editorFields = ckEditorFields;
      let dataUsers = await this.companyEntityDetails(users);
      for (const user of dataUsers) {
        let cType = user.contact_type;
        this.allMentionableFields = await this.fetchEntityDetails(cType, true);

        let seletedUserTemplateData = this.getTemplatesData.filter(
          (e) =>
            (user.entity_data_id &&
              user.entity_data_id._id &&
              e.entity_data_id == user.entity_data_id._id) ||
            e.entity_data_id == user.entity_data_id
        );

        let da = seletedUserTemplateData.filter((e) =>
          this.allMentionableFields.some(
            (el) => el.template_id === e.template_id
          )
        );

        let d1 = da;
        this.editorFields.forEach((editorField) => {
          if (
            !this.allMentionableFields.find(
              (e) => e.template_key === editorField.template_key
            )
          ) {
            this.allMentionableFields.push(editorField);
          }
        });
        this.allSelectedEntityMentionFields = this.allMentionableFields;

        this.allMentionableFields = this.allMentionableFields.map((item) => {
          const editorField = this.editorFields.find(
            (editorItem) => editorItem.template_key === item.template_key
          );

          const filledBy = editorField ? editorField.filled_by : null;

          return {
            label: item.label,
            template_key: item.template_key,
            id: item.template_key,
            input_type: item.input_type,
            value: this.findValueByKey(d1, item.template_key),
            width: item.width,
            list_data: item.list_data,
            options: item.options,
            filled_by: filledBy,
          };
        });
        const variableRegex = /\[\[(.*?)\]\]/g;
        let modifiedContent = editorContent;
        modifiedContent = modifiedContent.replace(
          variableRegex,
          (match, variableName) => {
            const matchingField = this.allMentionableFields.find(
              (field) => field.label === variableName
            );
            if (matchingField) {
              if (this.isBase64String(matchingField.value)){
                let val = matchingField.value;
                let imageHtml = `<img src="${val}" width="${matchingField.width}px" height="${matchingField.height}px" alt="${variableName}"/>`;
                return imageHtml;
              } else if (
                matchingField.value &&
                matchingField.value !== "" &&
                (matchingField.type === "DATE_TIME" || matchingField.input_type === "DATE_TIME")
              ) {
                const format = "MM-DD-YYYY HH:mm:ss";
                let dt = moment(matchingField.value).format(format);
                return `<span style="color: #000000;">${dt}</span>`;
              } else if (
                matchingField.value &&
                matchingField.value.length &&
                matchingField.value !== null &&
                (matchingField.type === "DATE_TIME_RANGE" || matchingField.input_type === "DATE_TIME_RANGE")
              ) {
                const format = "MM-DD-YYYY HH:mm:ss";
                let newAr = [];
                for (var i = 0; i < matchingField.value.length; i++) {
                  let obj = moment(matchingField.value[i]).format(format);
                  newAr.push(obj);
                }
                let contentValue = newAr.join(" To ");
                return `<span style="color: #000000;">${contentValue}</span>`;
              } else if (
                matchingField.value &&
                matchingField.value !== null &&
                matchingField.value.length &&
                (matchingField.type === "DATE_RANGE" || matchingField.input_type === "DATE_RANGE")
              ) {
                const format = "MM-DD-YYYY ";
                let newAr = [];
                for (i = 0; i < matchingField.value.length; i++) {
                  let obj = moment(matchingField.value[i]).format(format);
                  newAr.push(obj);
                }
                let contentValue = newAr.join(" To ");

                return `<span style="color: #000000;">${contentValue}</span>`;
              } else if (
                matchingField.value &&
                matchingField.value.length &&
                matchingField.value !== null &&
                (matchingField.type === "TIME_RANGE" || matchingField.input_type === "TIME_RANGE")
              ) {
                const format = " HH:mm:ss";
                let newAr = [];
                for (i = 0; i < matchingField.value.length; i++) {
                  let obj = moment(matchingField.value[i]).format(format);
                  newAr.push(obj);
                }
                let contentValue = newAr.join(" To ");
                return `<span style="color: #000000;">${contentValue}</span>`;
              } else if (
                matchingField.value &&
                matchingField.value !== "" &&
                (matchingField.type === "TIME" || matchingField.input_type === 'TIME')
              ) {
                const format = " HH:mm:ss";
                let dt = moment(matchingField.value).format(format);
                return `<span style="color: #000000;">${dt}</span>`;
              } else if (
                matchingField.value &&
                matchingField.value !== "" &&
                (matchingField.type === "DATE" || matchingField.input_type === "DATE")
              ) {
                const format = "MM/DD/YYYY";
                let dt = moment(matchingField.value).format(format);
                return `<span style="color: #000000;">${dt}</span>`;
              }else if(matchingField.value !== null ) {
                return matchingField.value;
              }else{
                return match;
              }
            }
            return match;
          }
        );

        modifiedContents.push(modifiedContent);
      }
      this.workflowData.templates_used.map((e) => {
          if (e.template_id._id === template.template_id._id) {
            e.template_id.ck_editor_content = template.template_id.ck_editor_content,
            e.template_id.ckeditor_fields = template.template_id.ckeditor_fields
          }
        });
      this.loading = false;
      return modifiedContents;
    },
    isBase64String(value) {
      const regex = /^data:image\/[a-z]+;base64,/;
      return regex.test(value);
    },
    findValueByKey(array, key) {
      const [templateId, field] = key.split("#");
      const foundItem = array.find((item) => item.template_id === templateId);
      if (
        foundItem &&
        foundItem.template_data &&
        foundItem.template_data[field]
      ) {
        return foundItem.template_data[field];
      } else {
        return null;
      }
    },
    getTextColorByFilledBy() {
      return (item) => {
        let style = "";
        if (item.filled_by || item.selected_user) {
          this.documentRecipientsList.map((user) => {
            if (
              (user.value == item.filled_by ||
                user.value == item.selected_user) &&
              user.color
            ) {
              style = `color: ${user.color}`;
            }
          });
          return style;
        } else {
          style = "color: #000000";
        }
        if (item.type != "logo") {
          return style;
        }
        return "";
      };
    },
    handleTextAreaFocus() {
      this.isFieldEditting = true;
    },
    handleTextAreaBlur() {
      this.isFieldEditting = false;
    },
    getTimeProperties(item) {
      if (item.time_range && item.step_time) {
        let properties = {};
        properties["start"] = moment(item.time_range[0]).format("HH:mm:ss");
        properties["end"] = moment(item.time_range[1]).format("HH:mm:ss");
        if (item.step_time == 60 || item.step_time == "60") {
          properties["step"] = "01:00:00";
        } else {
          properties["step"] = "00:" + item.step_time + ":00";
        }
        return properties;
      }
      return {
        start: "00:00:00",
        step: "00:30:00",
        end: "23:59:00",
      };
    },
    handleEnterAddressToContent(item) {
      // Find the corresponding element in the elements array
      const matchingElement = this.elements.find(
        (element) => element.key === item.key
      );
      if (
        matchingElement &&
        matchingElement.content === "" &&
        matchingElement.value
      ) {
        // Assign the value to the content property
        matchingElement.content = item.value;
      }
    },
    async fetchCompanyDetails() {
      let id =
        this.getActiveWorkspace &&
        this.getActiveWorkspace.company_id &&
        this.getActiveWorkspace.company_id._id
          ? this.getActiveWorkspace.company_id._id
          : this.getActiveWorkspace.company_id;
      await this.$store.dispatch("company/fetchCompany", id);
    },
    removeHashesFromKey(str) {
      return str.replace(/#/g, "");
    },
    setColor(selectedSignatureItem, color) {
      this.$set(selectedSignatureItem, "color", color);
    },
    captureSignature(item, index, pageIndex) {
      if (this.checkFieldAllow(item)) {
        return;
      }
      this.selectedSignatureItem = item;
      this.selectedSignatureItemIndex = index;
      this.pageIndex = pageIndex;
      this.captureSignatureDialog = true;
    },
    onSignDocument() {
      this.eSignatureDialogVisible = true;
    },
    async updateDocumentSignature(eSignData) {
      try {
        this.eSignatureDialogVisible = false;
        this.eSignId = eSignData.e_signature_id;
        this.elements[this.selectedSignatureItemIndex].value =
          eSignData.signature;
        this.elements[this.selectedSignatureItemIndex].source =
          eSignData.signature;
        this.captureSignatureDialog = false;
        this.goToNextField();
      } catch (err) {
        console.log("updateDocumentSignature Error", err);
      }
    },
    async onESignView() {
      try {
        this.showSavedSignatureData = true;
        this.esignLoading = true;
        let user = this.getCurrentAuthUser();
        let params = {
          id: user.e_signature,
        };
        await this.$store.dispatch("employeeData/fetchESignData", params);
        this.esignLoading = false;
        this.esignData = this.getESignData;
      } catch (err) {
        console.log("onESignView Error", err);
      }
    },
    captureInitialFromPopup(item, index, pageIndex) {
      if (this.checkFieldAllow(item)) {
        return;
      }
      this.selectedSignatureItem = item;
      this.selectedSignatureItemIndex = index;
      this.pageIndex = pageIndex;

      // if (!this.setInitial()) {
      this.captureInitialDialog = true;
      // }
    },
    handleInitialClick(item, index, pageIndex) {
      this.clickCount++;
      if (this.clickCount === 1) {
        var self = this;
        this.singleClickTimer = setTimeout(function () {
          self.clickCount = 0;
          self.captureInitial(item, index, pageIndex);
        }, 400);
      } else if (this.clickCount === 2) {
        clearTimeout(this.singleClickTimer);
        this.clickCount = 0;
        this.captureInitialFromPopup(item, index, pageIndex);
      }
    },
    captureInitial(item, index, pageIndex) {
      if (this.checkFieldAllow(item)) {
        return;
      }
      this.selectedSignatureItem = item;
      this.selectedSignatureItemIndex = index;
      this.pageIndex = pageIndex;

      if (!this.setInitial()) {
        this.captureInitialDialog = true;
      }
    },
    setInitial(isFromPopup = false) {
      try {
        if (!isFromPopup) {
          if (this.currentAuthenticatedUserInitial != null) {
            this.selectedSignatureItem.source =
              this.currentAuthenticatedUserInitial;
            this.selectedSignatureItem.value =
              this.currentAuthenticatedUserInitial;
            this.elements[this.selectedSignatureItemIndex] =
              this.selectedSignatureItem;
            return true;
          }
        } else {
          if (
            this.$refs.myInitialPad &&
            this.$refs.myInitialPad.getInitialUrl &&
            this.$refs.myInitialPad.getInitialUrl == null
          ) {
            this.selectedSignatureItem.source =
              this.$refs.myInitialPad.getAuthenticatedUser.initial;
            this.selectedSignatureItem.value =
              this.$refs.myInitialPad.getAuthenticatedUser.initial;
            this.elements[this.selectedSignatureItemIndex] =
              this.selectedSignatureItem;
          } else {
            this.selectedSignatureItem.source =
              this.$refs.myInitialPad.getInitialUrl;
            this.selectedSignatureItem.value =
              this.$refs.myInitialPad.getInitialUrl;
            this.elements[this.selectedSignatureItemIndex] =
              this.selectedSignatureItem;
          }
          this.currentAuthenticatedUserInitial =
            this.selectedSignatureItem.source;
          return true;
        }
      } catch (err) {
        console.log(">>> setInitial(), #10", err);
      }
      return false;
    },
    async saveInitial() {
      this.setInitial(true);
      this.captureInitialDialog = false;
    },

    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    getIndexOfField() {
      let index = this.currentFieldIndex + 1;
      if (index >= this.elements.length) {
        this.currentFieldIndex = -1;
        return -1; // Terminate the recursive call
      } else {
        if (index >= this.elements.length) {
          this.currentFieldIndex = -1;
          // index = this.currentFieldIndex + 1;
          return this.getIndexOfField();
        } else {
          let field = this.elements[index];
          if (field && field.required && !field.is_use_boxes && !field.value) {
            this.currentFieldIndex = this.currentFieldIndex + 1;
            return this.currentFieldIndex;
          } else if (
            field.type == "PRIMARY_FIELDS" &&
            !field.is_use_boxes &&
            !field.initial_value &&
            !field.content
          ) {
            this.currentFieldIndex = this.currentFieldIndex + 1;
            return this.currentFieldIndex;
          } else if (
            field.type == "PRIMARY_FIELDS" &&
            field.is_use_boxes &&
            !field.initial_value &&
            !field.content
          ) {
            this.currentFieldIndex = this.currentFieldIndex + 1;
            return this.currentFieldIndex;
          } else if (
            field.is_use_boxes == true &&
            field.boxes.length == 0 &&
            !field.content
          ) {
            this.currentFieldIndex = this.currentFieldIndex + 1;

            return this.currentFieldIndex;
          } else if (field.type == "ATTACHMENT" && field.required) {
            this.currentFieldIndex = this.currentFieldIndex + 1;
            if (field.requiredFiles) {
              if (field.files && field.files.length >= field.requiredFiles) {
                return this.currentFieldIndex;
              } else {
                return index;
              }
            } else {
              if (field.files && field.files.length) {
                return this.currentFieldIndex;
              } else {
                return index;
              }
            }
          } else if (field.type == "FORMULA") {
            this.currentFieldIndex = this.currentFieldIndex + 1;
            return this.currentFieldIndex;
          } else if (
            field.type == "CHECKBOX" &&
            field.group_key &&
            !field.is_use_cross_mark
          ) {
            this.currentFieldIndex = this.currentFieldIndex + 1;
            if (this.CheckboxIsFilledOrNOt(field)) {
              return this.currentFieldIndex;
            } else {
              return index;
            }
          } else if (
            field.type == "CHECKBOX" &&
            field.group_key &&
            field.is_use_cross_mark
          ) {
            this.currentFieldIndex = this.currentFieldIndex + 1;
            if (this.CheckboxIsFilledOrNOt(field)) {
              return this.currentFieldIndex;
            } else {
              return index;
            }
          } else {
            this.currentFieldIndex = this.currentFieldIndex + 1;
            if (
              (!field.source && field.required) ||
              (field.type == "PRIMARY_FIELDS" &&
                !(field.type == "CHECKBOX" && field.group_key) &&
                !field.content &&
                !field.is_use_boxes)
            ) {
              return index;
            } else {
              return this.currentFieldIndex;
            }
          }
        }
      }
    },
    getIndexOfPrevField() {
      let index = this.currentFieldIndex > -1 ? this.currentFieldIndex - 1 : -1;

      if (index < 0) {
        this.currentFieldIndex = this.elements.length;
        // index = this.currentFieldIndex + 1;
        return this.getIndexOfPrevField();
      } else {
        let field = this.elements[index];
        if (field && field.required && !field.is_use_boxes && !field.value) {
          this.currentFieldIndex = this.currentFieldIndex + 1;

          return this.currentFieldIndex;
        } else if (
          field.type == "PRIMARY_FIELDS" &&
          !field.is_use_boxes &&
          !field.initial_value &&
          !field.content
        ) {
          this.currentFieldIndex = this.currentFieldIndex + 1;
          return this.currentFieldIndex;
        } else if (
          field.type == "PRIMARY_FIELDS" &&
          field.is_use_boxes &&
          !field.initial_value &&
          !field.content
        ) {
          this.currentFieldIndex = this.currentFieldIndex + 1;
          return this.currentFieldIndex;
        } else if (
          field.is_use_boxes == true &&
          field.boxes.length == 0 &&
          !field.content
        ) {
          this.currentFieldIndex = this.currentFieldIndex + 1;

          return this.currentFieldIndex;
        } else if (field.type == "ATTACHMENT" && field.required) {
          this.currentFieldIndex = this.currentFieldIndex + 1;
          if (field.requiredFiles) {
            if (field.files && field.files.length >= field.requiredFiles) {
              return this.currentFieldIndex;
            } else {
              return index;
            }
          } else {
            if (field.files && field.files.length) {
              return this.currentFieldIndex;
            } else {
              return index;
            }
          }
        } else if (field.type == "FORMULA") {
          this.currentFieldIndex = this.currentFieldIndex + 1;
          return this.currentFieldIndex;
        } else if (
          field.type == "CHECKBOX" &&
          field.group_key &&
          !field.is_use_cross_mark
        ) {
          this.currentFieldIndex = this.currentFieldIndex + 1;
          if (this.CheckboxIsFilledOrNOt(field)) {
            return this.currentFieldIndex;
          } else {
            return index;
          }
        } else if (
          field.type == "CHECKBOX" &&
          field.group_key &&
          field.is_use_cross_mark
        ) {
          this.currentFieldIndex = this.currentFieldIndex + 1;
          if (this.CheckboxIsFilledOrNOt(field)) {
            return this.currentFieldIndex;
          } else {
            return index;
          }
        } else {
          this.currentFieldIndex = this.currentFieldIndex + 1;
          if (
            (!field.source && field.required) ||
            (field.type == "PRIMARY_FIELDS" &&
              !(field.type == "CHECKBOX" && field.group_key) &&
              !field.content &&
              !field.is_use_boxes)
          ) {
            return index;
          } else {
            return this.currentFieldIndex;
          }
        }
      }
    },
    goToPrevField() {
      let index = this.getIndexOfPrevField();
      if (this.elements && this.elements.length && this.elements[index]) {
        this.currentEnabledFieldKey = this.elements[index].key;
        this.currentEnabledField = this.elements[index];
        let key = this.removeHashesFromKey(this.elements[index].key);
        // if (
        //   this.elements[index]["type"] == "SIGNATURE" &&
        //   !this.elements[index].source
        // ) {
        //   this.captureSignature(this.elements[index], index);
        // }
        if (this.$refs[key] && this.$refs[key][0]) {
          if (this.elements[index]["type"] == "CURRENCY") {
            this.$refs[key][0].$el.focus();
          } else if (this.elements[index]["type"] == "YES_OR_NO") {
            this.$refs[key][0].$el.focus();
          } else if (this.elements[index]["type"] == "CHECKBOX") {
            this.$refs[key][0].focus();
            this.$refs[key][0].$el.focus();
          } else {
            this.$refs[key][0].focus();
            this.$refs[key][0].$el.focus();
          }
        }
      }
    },
    goToNextField() {
      let index = this.getIndexOfField();
      if (this.elements && this.elements.length && this.elements[index]) {
        let key = this.removeHashesFromKey(this.elements[index].key);
        this.currentEnabledFieldKey = this.elements[index].key;
        this.currentEnabledField = this.elements[index];
        // if (
        //   this.elements[index]["type"] == "SIGNATURE" &&
        //   !this.elements[index].source
        // ) {
        //   this.captureSignature(this.elements[index], index);
        // }

        if (this.$refs[key] && this.$refs[key][0]) {
          if (this.elements[index]["type"] == "CURRENCY") {
            this.$refs[key][0].$el.focus();
          } else if (this.elements[index]["type"] == "YES_OR_NO") {
            this.$refs[key][0].$el.focus();
          } else {
            this.$refs[key][0].focus();
            this.$refs[key][0].$el.focus();
          }
        }
      }
    },
    async getCurrentUserFieldsCount() {
      let senderEmptyfields = [];
      this.unfilledFields = [];
      senderEmptyfields = this.elements.filter((el) => {
        if (this.unWantedFields.indexOf(el.type) == -1) {
          if (
            el.filled_by == "SENDER" &&
            el.type == "MULTI_SELECT" &&
            el.value &&
            !el.value.length
          ) {
            return el;
          } else if (
            el.filled_by == "SENDER" &&
            el.type == "NUMBER" &&
            !el.group_key &&
            el.required &&
            (!el.value || el.value == "")
          ) {
            return el;
          } else if (
            el.filled_by == "SENDER" &&
            el.type == "CHECKBOX" &&
            el.group_key &&
            this.checkIsCheckBoxFilledOrNot(el)
          ) {
            return el;
          } else if (
            el.filled_by == "SENDER" &&
            el.type == "CHECKBOX" &&
            el.required &&
            !el.group_key &&
            !el.value
          ) {
            return el;
          } else if (el.filled_by == "SENDER" && el.type == "ATTACHMENT") {
            if (el.requiredFiles) {
              if (el.files && el.files.length < el.requiredFiles) {
                return el;
              }
            } else {
              if (el.files && !el.files.length) {
                return el;
              }
            }
          } else if (el.filled_by == "SENDER" && el.type == "PRIMARY_FIELDS") {
            if (!el.content) {
              return el;
            }
          } else if (
            el.filled_by == "SENDER" &&
            el.type == "ENTITY" &&
            !el.entity_data_id
          ) {
            return el;
          } else if (
            el.filled_by == "SENDER" &&
            el.field_type == "CUSTOM_FIELD" &&
            el.type != "CHECKBOX" &&
            !el.group_key &&
            (el.required || el.required == true) &&
            !el.value
          ) {
            return el;
          } else if (
            el.filled_by == "SENDER" &&
            el.field_type == "ENTITY_FIELD" &&
            el.type != "CHECKBOX" &&
            !el.group_key &&
            (el.required || el.required == true) &&
            !el.value
          ) {
            return el;
          } else if (
            el.filled_by == "SENDER" &&
            !el.group_key &&
            el.required &&
            !el.value
          ) {
            return el;
          }
        }
      });

      if (senderEmptyfields && senderEmptyfields.length) {
        senderEmptyfields.forEach((field) => {
          // let checksData = this.checkBoxGroupArray.find(
          //   (el) => el && el.key && el.key == field.group_key
          // );
          // let groupFilledFields = this.elements.filter(
          //   (e) =>
          //     e.type == "CHECKBOX" &&
          //     e.group_key &&
          //     checksData &&
          //     e.group_key == checksData.key &&
          //     e.value
          // );
          // if (
          //   field.type == "CHECKBOX" &&
          //   field.group_key &&
          //   this.unfilledFields.indexOf(field.group_key) === -1 &&
          //   groupFilledFields &&
          //   groupFilledFields.length < checksData.min_value
          // ) {
          //   this.unfilledFields.push(field.group_key);
          //   return;
          // } else if (
          //   groupFilledFields &&
          //   checksData &&
          //   groupFilledFields.length >= checksData.min_value
          // ) {
          //   let index = this.unfilledFields.indexOf(field.group_key);
          //   if (index !== -1) {
          //     this.unfilledFields.splice(index, 1);
          //     return;
          //   }
          // } else if (
          //   !field.group_key &&
          //   this.unfilledFields.indexOf(field.label) === -1
          // ) {
          this.unfilledFields.push(field);
          // }
        });
        this.showFinishErrorElementDialog = true;
      } else {
        this.showFinishErrorElementDialog = false;
        await this.prepareTempData();
        this.renderNextTemplate();
      }
    },
    closeAlertPopup() {
      this.names = [];
      this.showSignErrorElementDialog = false;
      this.showFinishErrorElementDialog = false;
    },
    async saveWorkflowData() {
      await this.$store.dispatch(
        "templateWorkflow/updateWorkflowSavingData",
        this.getWorkflowSavingData
      );
      if (this.$route.query && this.$route.query.group) {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "Workflows",
          query,
        });
      } else if (
        this.$route.query &&
        this.$route.query.routeType &&
        this.$route.query.t
      ) {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "Workflows-Data",
          params: {
            workflow_id: this.isEdit
              ? this.$route.params.workflow_id
              : this.getCreatedworkflowDataById.workflow_id,
          },
          query: {
            ...query,
            ...this.isMenuNavigation(),
          },
        });
      }
      if (this.isEdit) {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "Workflows-Data",
          params: {
            workflow_id: this.isEdit
              ? this.$route.params.workflow_id
              : this.getCreatedworkflowDataById.workflow_id,
          },
          query,
        });
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "Workflows",
          query,
        });
      }
    },
    handleInputChange(item) {
      if (!item.value) {
        item.value = item.boxes.join("");
      } else {
        item.value = item.boxes.join("");
      }
    },
    handleClose() {
      this.newEntityForm = {};
      this.quickAddNewEntityData = false;
    },
    async addNewEntityDataQuickAdd() {
      try {
        this.loading = true;
        let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.newEntityForm && !this.newEntityForm.name) {
          this.newEntityForm.name =
            this.newEntityForm.first_name + " " + this.newEntityForm.last_name;
        }
        if (!re.test(this.newEntityForm.email)) {
          this.loading = false;

          this.$message({
            message: "Invalid email format!",
            type: "error",
          });
          return;
        }
        // let checkData = this.checkFormData()
        let data = {
          entity_details_id: null,
          template_id: this.quickAddStandardTemplteIndividualEntity.template_id,
          template_data: this.newEntityForm,
          entity_id: this.currentIndividualEntity._id,
          template_completion_status: true,
          entity_data_id: null,
        };
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          data
        );
        if (this.getEntityDataCreateByTemplateStatus) {
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: "Data saved successfully",
          });
          if (this.getNewEntityData) {
            bus.$emit("quick-add-new-updated", {
              entity_id: this.getNewEntityData.entity_id,
              entity_data_id: this.getNewEntityData._id,
              form: this.selectedEntityQuickAddField,
              reset: true,
            });
          }
          this.newEntityForm = {};
          this.quickAddNewEntityData = false;
          this.currentEntityOfQuickAdd = [];
        } else {
          this.loading = false;
          if (
            this.getNewEntityCreateError &&
            this.getNewEntityCreateError.message
          ) {
            this.$notify.error({
              title: "Error",
              message: this.getNewEntityCreateError.message,
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving data",
            });
          }
        }
        this.setCurrentEntityValue = true;
      } catch (err) {
        console.log("updateEntitiesData Error", err);
      }
    },
    async updateEntitiesData(dataId) {
      try {
        this.loading = true;
        // ** mixin method ** //
        let toAssignRelationshipsData = this.prepareRelationshipData(
          this.currentEntityOfQuickAdd,
          this.currentEntityRelationships,
          dataId,
          null,
          this.newEntityForm
        );
        if (toAssignRelationshipsData.length) {
          await this.$store.dispatch(
            "entityRelationships/assignFieldsEntityRelationshipData",
            toAssignRelationshipsData
          );
          this.loading = false;
        } else {
          // await this.assignEntitiesData();
        }
      } catch (err) {
        console.log("updateEntitiesData Error", err);
      }
    },
    async addPrimaryDataToEntity() {
      try {
        this.loading = true;
        if (this.newEntityForm) {
          let data = {
            primaryFields: this.currentEntityOfQuickAdd,
            formData: this.newEntityForm,
            templates: this.currentIndividualEntity.templates,
            entity_id: this.currentIndividualEntity._id,
          };
          await this.$store.dispatch(
            "templatesData/createEntityPrimaryData",
            data
          );
          if (this.getPrimaryEntityDataStatus) {
            if (this.getNewEntityData && this.getNewEntityData._id) {
              await this.updateEntitiesData(this.getNewEntityData._id);
              bus.$emit("quick-add-new-updated", {
                entity_id: this.getNewEntityData.entity_id,
                entity_data_id: this.getNewEntityData._id,
                form: this.selectedEntityQuickAddField,
                reset: true,
              });
              this.quickAddNewEntityData = false;
            }
            this.$notify.success({
              title: "Success",
              message: "Primary Data saved successfully",
            });
            bus.$emit("quick-add-new-updated", {
              entity_id: this.getNewEntityData.entity_id,
              entity_data_id: this.getNewEntityData._id,
            });
            this.quickAddNewEntityData = false;
            this.loading = false;
            this.newEntityForm = {};
          } else {
            this.loading = false;
            let msg = "Error at saving data";
            if (
              this.getPrimaryEntityDataErrors &&
              this.getPrimaryEntityDataErrors.message
            ) {
              msg = this.getPrimaryEntityDataErrors.message;
            }
            this.$notify.error({
              title: "Error",
              message: msg,
            });
          }
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }
      } catch (err) {
        console.log("addPrimaryDataToEntity Error", err);
      }
    },

    handleBackspace(item, index) {
      if (
        index > 0 &&
        item.boxes[index] === "" &&
        item.boxes[index - 1] !== ""
      ) {
        this.$refs[`box${index - 1}`][0].focus();
        item.value = item.boxes.join("");
      }
    },
    getCheckBoxLabel(item) {
      if (item && item.type == "CHECKBOX" && item.group_key) {
        let checkLabel =
          this.templateData &&
          this.templateData[0] &&
          this.templateData[0].checkBoxGroups.find(
            (el) => el && el.key == item.group_key
          );
        if (checkLabel && checkLabel.show_checkbox_label) {
          return true;
        }
      } else {
        if (item.is_show_label) {
          return true;
        }
      }
      return false;
    },
    async prepareTempData() {
      await this.setPrimaryData(this.elements);
      this.allUsersData.map((temp) => {
        let relatedTemp = this.templateData.find((el) => {
          if (el.id == temp._id) {
            return el;
          }
        });
        if (relatedTemp) {
          let documentData = this.prepareDocumentData(relatedTemp.elements);
          temp.document_data = documentData;
        }
        return temp;
      });
      console.log('-=--',this.templateData)
      this.$emit("createWorkflowDocuments", {
        allUser: this.allUsersData,
        templateData: this.templateData,
        contactsData: this.userPrimaryData,
        entityData: this.userEntityData,
        finish_sender_side: true,
        workflowData: this.workflowData,
      });
    },
    prepareDocumentData(elements) {
      let data = {};
      elements.forEach((element) => {
        if (element.content) {
          data[element.key] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else if (element.files && element.files.length) {
          data[element.key] = element.files;
        } else {
          data[element.key] = "";
        }
      });
      return data;
    },
    getDateFieldStyles(item) {
      let style = `font-size:${item.font.size}px;font-style: ${
        item.font.style == 2 || item.font.style == 3 ? "italic" : "normal"
      };font-weight:${
        item.font.style == 1 || item.font.style == 3 ? "bold" : "normal"
      };color:${item.font.color};font-family:${
        item.font.name
      };position:absolute;left:${item.x}px;top:${item.y}px;height:${
        item.height
      }px;width:${item.width}px;`;
      if (item.userData && item.userData.color) {
        style += `outline: 2px solid ${item.userData.color} !important;`;
      }
      return style;
    },
    getFieldStylesForDate(item, additionalStyle = "") {
      let style = `font-size:${
        item && item.font && item.font.size ? item.font.size : "16"
      }px;font-style: ${
        (item && item.font && item.font.style == 2) ||
        (item && item.font && item.font.style == 3)
          ? "italic"
          : "normal"
      };font-weight:${
        (item && item.font && item.font.style == 1) ||
        (item && item.font && item.font.style == 3)
          ? "bold"
          : "normal"
      };color:${
        item && item.font && item.font.color ? item.font.color : "black"
      };font-family:${
        item && item.font && item.font.name ? item.font.name : "helvetica"
      }`;

      return style + ";" + additionalStyle;
    },
    getFieldStyles(item) {
      let style = `font-size:${item.font.size}px;font-style: ${
        item.font.style == 2 || item.font.style == 3 ? "italic" : "normal"
      };font-weight:${
        item.font.style == 1 || item.font.style == 3 ? "bold" : "normal"
      };color:${item.font.color};font-family:${
        item.font.name
      };position:absolute;left:${
        item.type == "PRIMARY_FIELDS" ? item.x : item.x
      }px;top:${item.type == "SINGLE_LINE_TEXT" ? item.y : item.y}px;height:${
        item.height
      }px;width:${item.width}px;${
        item.type == "CHECKBOX" && this.getCheckBoxLabel(item)
          ? "margin-left:32px;border:none !important"
          : ""
      }`;
      if (item.userData && item.userData.color) {
        style += `outline: 1px solid ${item.userData.color} !important;`;
      }

      if (item.type == "HORIZONTAL_LINE") {
        style += `outline: 1px solid transparent; border: none !important;`;
      }
      return style;
    },
    checkBoxDisabled(item) {
      if (item && item.type == "CHECKBOX" && item.group_key) {
        let currentActiveGroupOfAField =
          this.templateData &&
          this.templateData[this.currentRenderTemplateIndex] &&
          this.templateData[
            this.currentRenderTemplateIndex
          ].checkBoxGroups.find((gkey) => gkey.key == item.group_key);
        let groupSelectedFields = this.elements.filter(
          (field) =>
            field.group_key &&
            field.group_key == item.group_key &&
            field.value == true
        );
        let maxValue = parseInt(
          currentActiveGroupOfAField && currentActiveGroupOfAField.max_value
        );
        if (maxValue == 0 || !maxValue) {
          return false;
        }
        let fieldCount = groupSelectedFields.length;
        if (fieldCount >= maxValue && !item.value) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    getHeadingStyles(item) {
      return `font-family:${item.font.name};color:${item.font.color};`;
    },
    getStyle(item, isDecreasedHeight = false) {
      let bgColor =
        item.userData && item.userData.color
          ? `background: ${item.userData.color + "33"}; border: 2px solid ${
              item.userData.color
            }; color: #000000`
          : "";
      if (item.type == "SIGNATURE") {
        var style = "";
        if (isDecreasedHeight) {
          style = item.required
            ? `position:absolute;left:${item.x}px;top:${item.y}px;cursor:pointer;background:#c2d8de;width:${item.width}px;${bgColor}` +
              `;height:${item.height - 4}px;`
            : `position:absolute;left:${item.x}px;top:${item.y}px;cursor:pointer;width:${item.width}px;outline: 2px solid ${this.field_color.optional} !important;` +
              `;height:${item.height - 4}px;`;
        } else {
          style = item.required
            ? `position:absolute;left:${item.x}px;top:${item.y}px;cursor:pointer;background:#c2d8de;width:${item.width}px;${bgColor}` +
              `;height:${item.height}px;`
            : `position:absolute;left:${item.x}px;top:${item.y}px;cursor:pointer;width:${item.width}px;outline: 2px solid ${this.field_color.optional} !important;` +
              `;height:${item.height}px;`;
        }
        return style;
      } else if (item.type == "my-signature") {
        return `position:absolute;left:${item.x + 8}px;top:${item.y}px;height:${
          item.height
        }px;width:${item.width}px;cursor:pointer;${bgColor}`;
      } else if (item.type == "ATTACHMENT") {
        let style = `position:absolute;left:${item.x}px;top:${
          item.y
        }px;height:${item.height}px;width:${item.width + 3}px;${bgColor}`;
        if (item.userData && item.userData.color) {
          style += `outline: 2px solid ${item.userData.color} !important;`;
        }
        return style;
      } else if (item.type == "MULTI_LINE_TEXT") {
        let style = `position:absolute;left:${item.x}px;top:${item.y}px;height:auto;width:${item.width}px;${bgColor}`;
        if (item.userData && item.userData.color) {
          style += `outline: 2px solid ${item.userData.color} !important;`;
        }
        return style;
      } else if (item.type == "YES_OR_NO") {
        let style = item.required
          ? `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;${bgColor};`
          : `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.optional} !important;`;
        return style;
      }
      //  else if (item.type == "CHECKBOX") {
      //   let style = item.required
      //     ? `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;${this.field_color.default};`
      //     : `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.optional} !important;`;
      //   return style;
      // }
      else if (item.type == "CHECKBOX") {
        let style = `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;${bgColor}`;
        let checkBoxStyle =
          this.templateData &&
          this.templateData[0] &&
          this.templateData[0].checkBoxGroups.find(
            (el) => el && el.key == item.group_key && el.min_value >= 1
          );

        if (item.filled_by) {
          if (item.value) {
            style += `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.filled} !important;`;
          } else if (this.currentEnabledFieldKey == item.key) {
            style += `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.highlight} !important;`;
          } else if (
            item.group_key &&
            checkBoxStyle &&
            checkBoxStyle.min_value &&
            checkBoxStyle.min_value >= 1
          ) {
            style +=
              checkBoxStyle.min_value >= 1
                ? `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.default} !important;`
                : `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.optional} !important;`;
          } else {
            style += item.required
              ? `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.default} !important;`
              : `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.optional} !important;`;
          }
        }
        return style;
      } else {
        let style = item.required
          ? `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;${bgColor}`
          : `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;outline: 2px solid ${this.field_color.optional} !important;`;
        if (item.userData && item.userData.color) {
          style += `outline: 2px solid ${item.userData.color} !important;`;
        }
        return style;
      }
    },
    async setEntityData() {
      if(!this.isEditorCkContentReady){
      if (this.allUsersData && this.allUsersData.length) {
        let entity_data_ids = [];
        this.allUsersData.forEach((temp) => {
          if (temp && temp.document_users && temp.document_users.length) {
            temp.document_users.forEach((user) => {
              if (user.entity_data_id) {
                if (user.entity_data_id._id) {
                  entity_data_ids.push(user.entity_data_id._id);
                } else {
                  entity_data_ids.push(user.entity_data_id);
                }
              }
            });
          }
        });
        await this.$store.commit("templatesData/setTemplatesData", [], {
          root: true,
        });
        if (entity_data_ids && entity_data_ids.length) {
          await this.$store.dispatch("templatesData/fetchUserTemplateData", {
            entity_data_ids: entity_data_ids,
          });
          await this.setUsersEntityData();
        }
      }
    }
    },
    setUsersEntityData() {
      this.allUsersData.forEach((temp) => {
        if (temp && temp.document_users && temp.document_users.length) {
          temp.document_users.forEach((user) => {
            const seletedUserTemplateData = this.getTemplatesData.filter(
              (e) =>
                (user.entity_data_id &&
                  user.entity_data_id._id &&
                  e.entity_data_id == user.entity_data_id._id) ||
                e.entity_data_id == user.entity_data_id
            );
            seletedUserTemplateData.forEach((e) => {
              this.allTemplatesData[
                user.value + "-" + e.template_id + "-" + temp._id
              ] = e.template_data;
            });
          });
        }
      });
    },
    async arrangeTemplateData() {
        for (let i = 0; i < this.workflowData.templates_used.length; i++) {
          let temp =
            this.workflowData.templates_used[i];
          if(temp && temp.template_id && temp.template_id.ck_editor_content && temp.template_id.ck_editor_content.length){
            this.templateData.push({
            id: temp.template_id._id,
            ck_editor_content:temp.template_id.ck_editor_content,
            ckeditor_fields:temp.template_id.ckeditor_fields,
            name: temp.template_id.name,
            checkBoxGroups: temp.template_id.pages[0].checkbox_group,
            elements: temp.template_id.pages[0].fields,
            scale: temp.scale,
          });
          this.checkBoxGroupArray = temp.template_id.pages[0].checkbox_group;
          }else{
          let urls = [];
          if (
            temp.template_id.document_upload_urls &&
            temp.template_id.document_upload_urls.length
          ) {
            urls = temp.template_id.document_upload_urls;
          } else {
            urls = [temp.template_id.document_upload_url];
          }
          let downloadUrls = await this.getDownloadFilesURLFromS3Paths(urls);
          //let downloadUrls =  temp.template_id.document_download_s3_url ? temp.template_id.document_download_s3_url : await this.getDownloadFilesURLFromS3Paths(urls);

          this.templateData.push({
            id: temp.template_id._id,
            document_upload_url: temp.template_id.document_upload_url
              ? downloadUrls[0]
              : null,
            document_upload_urls: temp.template_id.document_upload_urls
              ? downloadUrls
              : [],
            name: temp.template_id.name,
            checkBoxGroups: temp.template_id.pages[0].checkbox_group,
            elements: temp.template_id.pages[0].fields,
            scale: temp.scale,
          });
          this.checkBoxGroupArray = temp.template_id.pages[0].checkbox_group;
        
        }

        this.templateData = this.templateData.map((temp) => {
          console.log("-=-=temp", temp);
          let relatedTemp = this.allUsersData.find((el) => {
            if (temp.id == el._id) {
              return el;
            }
          });
          if (relatedTemp) {
            let tempKeyValue = {};
            let counttemplate = {};
            // let count = 1;
            temp.elements = temp.elements.map((el) => {             
              if (el && el.type == "PRIMARY_FIELDS") {
                this.$set(
                  el,
                  "content",
                  this.getFieldVal(
                    el.selected_tag,
                    el.selected_user,
                    relatedTemp.document_users,
                    el.selected_tag == "address" &&
                      el.addressInfo === "Company Address"
                      ? true
                      : false
                  )
                );
                if (!el.value) {
                  this.$set(
                    el,
                    "value",
                    this.getFieldVal(
                      el.selected_tag,
                      el.selected_user,
                      relatedTemp.document_users,
                      el.selected_tag == "address" &&
                        el.addressInfo === "Company Address"
                        ? true
                        : false
                    )
                  );
                }
              }
              if (
                el &&
                el.template_id &&
                el.selected_user != "SENDER" &&
                el.field_type != "ENTITY_VARIABLE"
              ) {
                let repeatableObj =
                  this.allTemplatesData[
                    el.selected_user + "-" + el.template_id + "-" + temp.id
                  ];
                if (el.type == "REPEATABLE_PARENT") {
                  let repeatableData =
                    repeatableObj &&
                    Object.keys(repeatableObj)[0] &&
                    repeatableObj[Object.keys(repeatableObj)[0]]
                      ? repeatableObj[Object.keys(repeatableObj)[0]]
                      : [];
                  el.repeatable_data = repeatableData;
                } else if (el.indexKey) {
                  let key = el.key.split("-")[2];
                  if (key && key.includes("#")) {
                    key = key.split("#")[0];
                  }
                  if (
                    tempKeyValue[
                      temp.id +
                        "-" +
                        el.template_id +
                        "-" +
                        el.selected_user +
                        "-" +
                        el.indexKey
                    ]
                  ) {
                    let count =
                      tempKeyValue[
                        temp.id +
                          "-" +
                          el.template_id +
                          "-" +
                          el.selected_user +
                          "-" +
                          el.indexKey
                      ];
                    let repeatableData =
                      repeatableObj &&
                      Object.keys(repeatableObj)[0] &&
                      repeatableObj[Object.keys(repeatableObj)[0]]
                        ? repeatableObj[Object.keys(repeatableObj)[0]]
                        : [];
                    if (el.type == "ENTITY") {
                      el.entity_data_id =
                        repeatableData &&
                        repeatableData[count - 1] &&
                        repeatableData[count - 1][key]
                          ? repeatableData[count - 1][key]
                          : "";
                      el.value =
                        repeatableData &&
                        repeatableData[count - 1] &&
                        repeatableData[count - 1][key + "/name"]
                          ? repeatableData[count - 1][key + "/name"]
                          : "";
                    } else {
                      el.value =
                        repeatableData &&
                        repeatableData[count - 1] &&
                        repeatableData[count - 1][key]
                          ? repeatableData[count - 1][key]
                          : "";
                    }
                  } else {
                    let count = counttemplate[
                      el.selected_user + "-" + el.template_id
                    ]
                      ? (counttemplate[
                          el.selected_user + "-" + el.template_id
                        ] =
                          counttemplate[
                            el.selected_user + "-" + el.template_id
                          ] + 1)
                      : (counttemplate[
                          el.selected_user + "-" + el.template_id
                        ] = 1);
                    tempKeyValue[
                      temp.id +
                        "-" +
                        el.template_id +
                        "-" +
                        el.selected_user +
                        "-" +
                        el.indexKey
                    ] = count;
                    let repeatableData =
                      repeatableObj &&
                      Object.keys(repeatableObj)[0] &&
                      repeatableObj[Object.keys(repeatableObj)[0]]
                        ? repeatableObj[Object.keys(repeatableObj)[0]]
                        : [];
                    if (el.type == "ENTITY") {
                      el.entity_data_id =
                        repeatableData &&
                        repeatableData[count - 1] &&
                        repeatableData[count - 1][key]
                          ? repeatableData[count - 1][key]
                          : "";
                      el.value =
                        repeatableData &&
                        repeatableData[count - 1] &&
                        repeatableData[count - 1][key + "/name"]
                          ? repeatableData[count - 1][key + "/name"]
                          : "";
                    } else {
                      el.value =
                        repeatableData &&
                        repeatableData[count - 1] &&
                        repeatableData[count - 1][key]
                          ? repeatableData[count - 1][key]
                          : "";
                    }
                  }
                } else {
                  if (repeatableObj) {
                    let key = el.key.split("-")[1];
                    if (key && key.includes("#")) {
                      key = key.split("#")[0];
                    }
                    if (el.type == "ENTITY") {
                      el.entity_data_id = repeatableObj[key]
                        ? repeatableObj[key]
                        : "";
                      el.value = repeatableObj[key + "/name"]
                        ? repeatableObj[key + "/name"]
                        : "";
                    } else {
                      el.value = repeatableObj[key] ? repeatableObj[key] : "";
                    }
                  } else {
                    el.value = "";
                  }
                }
              }
              if (!el.font) {
                el.font = {
                  name: "Helvetica",
                  size: 16,
                  style: 0,
                  color: "#000000",
                };
              }
              let user = el.filled_by || el.selected_user;
              el.userData = this.getFieldColor(
                user,
                relatedTemp.document_users
              );
              return el;
            });
          }
          return temp;
        });
      }
    },
    getFieldColor(user, users) {
      let data = {};
      let selectedUser = (users || []).find((e) => e.value == user);
      if (selectedUser) {
        if (selectedUser.color) data.color = selectedUser.color;
        if (selectedUser.first_name && selectedUser.last_name)
          data.user = selectedUser.first_name + " " + selectedUser.last_name;
      }
      return data;
    },
    getFieldVal(field, userType, users, isCompanyAddress) {
      let fieldVal = "";
      users = [...users];
      if (field == "full_name") {
        users.map((user) => {
          if (user.value == userType) {
            if (user["first_name"] && user["last_name"]) {
              fieldVal = user["first_name"] + " " + user["last_name"];
            } else {
              fieldVal = undefined;
            }
          }
        });
      } else if (field === "address") {
        users.map((user) => {
          if (user.value == userType) {
            if (user.type == "SENDER") {
              if (isCompanyAddress) {
                fieldVal = this.getCompanyDetails.address;
              } else {
                fieldVal = this.getAuthenticatedUser.address;
              }
            }
          }
        });
      } else {
        users.map((user) => {
          if (user.value == userType) {
            fieldVal = user[field];
          }
        });
      }
      if (!fieldVal) {
        if (field === "company_name") {
          users.map((user) => {
            if (user.value == userType) {
              if (user.user_id && user.user_id.company_name) {
                fieldVal = user.user_id.company_name;
              }
            }
          });
        }
      }
      return fieldVal;
    },
    setPrimaryData(elements) {
      let relatedTemp = this.allUsersData.find((el) => {
        if (this.currentRenderTemplate.id == el._id) {
          return el;
        }
      });
      let userValues = {};
      if (relatedTemp && relatedTemp.document_users) {
        relatedTemp.document_users.forEach((user) => {
          if (user.value && user.email) {
            userValues[user.value] = user.email;
          }
        });
      }
      let entityDataObj = this.prepareUserEntityData(
        relatedTemp.document_users,
        elements,
        this.filesData
      );
      if (this.userEntityData && Object.keys(this.userEntityData).length) {
        Object.keys(entityDataObj).forEach((entity) => {
          if (this.userEntityData[entity]) {
            const entityData = entityDataObj[entity];
            let oldEntityData = this.userEntityData[entity];
            let finalEntityData = {};
            Object.keys(entityData).forEach((e) => {
              if (
                entityData[e] &&
                entityData[e].isRepeatable &&
                entityData[e]["data"] &&
                oldEntityData[e] &&
                oldEntityData[e].isRepeatable
              ) {
                let repeatableData = entityData[e]["data"];
                let existedData = oldEntityData[e]["data"];
                let finalRepeatableData = [];
                let result = [];
                if (repeatableData && repeatableData.length) {
                  repeatableData.forEach((el) => {
                    let newTemplateData = {};
                    const dataObj = existedData.find(
                      (o) => o.indexKey == el.indexKey
                    );
                    if (dataObj) {
                      newTemplateData = { ...dataObj, ...el };
                    } else {
                      newTemplateData = { ...el };
                    }
                    finalRepeatableData.push(newTemplateData);
                  });
                }
                if (existedData && existedData.length) {
                  let indexs = finalRepeatableData
                    .filter((e) => e.indexKey)
                    .flatMap((e) => e.indexKey);
                  existedData.forEach((te) => {
                    if (te && indexs.indexOf(te.indexKey) == -1) {
                      result.push(te);
                    }
                  });
                }
                finalEntityData[e] = {
                  isRepeatable: true,
                  data: [...finalRepeatableData, ...result],
                };
              } else {
                finalEntityData[e] = { ...oldEntityData[e], ...entityData[e] };
              }
            });
            this.userEntityData[entity] = {
              ...this.userEntityData[entity],
              ...finalEntityData,
            };
          } else {
            this.userEntityData[entity] = { ...entityDataObj[entity] };
          }
        });
      } else {
        this.userEntityData = { ...entityDataObj };
      }
      elements.forEach((el) => {
        if (el.type == "PRIMARY_FIELDS" && userValues[el.selected_user]) {
          let obj = {};
          obj[el.selected_tag] = el.content
            ? el.content
            : el.value
            ? el.value
            : "";
          this.userPrimaryData[userValues[el.selected_user]] = {
            ...this.userPrimaryData[userValues[el.selected_user]],
            ...obj,
          };
        }
        if (el.selected_tag === "address") {
          el.content = el.value;
        }
      });
    },
    async replaceUserData(template) {
      console.log('--=1')
      for (let i = 0; i < template.template_id.document_users.length; i++) {
        const user = template.template_id.document_users[i];
        if (user.email === "") {
          for (let userData of this.allUsersData) {
            const matchingUser = userData.document_users.find(
              (u) =>
                template.template_id._id === u._id &&
                u.value === user.value &&
                u.email
            );
            if (matchingUser) {
              template.template_id.document_users[i] = matchingUser;
              break;
            }
          }
        }
      }
    },
    async checkGenerateCkEditorToPdf() {
    try {
      console.log('-checj- 111 times')
      this.editorCkContent = "";
      console.log('this.currentRenderTemplateIndex',this.currentRenderTemplateIndex)
      const template = this.workflowData.templates_used[this.currentRenderTemplateIndex];
      console.log("-------template-----", template);
      await this.replaceUserData(template);
      if (
        template.template_id.ck_editor_content !== ""
      ) {
        let ckdata = await this.replaceVariables(
          template.template_id.ck_editor_content,
          template.template_id.ckeditor_fields,
          template,
          template.template_id.document_users
        );
        let ckCont = ckdata[ckdata.length - 1];
        this.editorContentInPreviewPage = ckCont;
        this.isEditorCkContentReady = true;
        // await this.saveAndGeneratePdf(ckCont, template);
      }
    } catch (err) {
      console.log(err);
    }
  },
    async renderNextTemplate() {
      this.elements = this.elements.map((e) => {
        if (
          e &&
          e.type === "PRIMARY_FIELDS" &&
          e.content === "" &&
          e.value !== ""
        ) {
          return {
            ...e,
            content: e.value,
          };
        } else {
          return e;
        }
      });
      let tempElements = this.elements;
      this.elements = [];
      this.loading = true;

      if (this.templateData && this.templateData.length) {
        this.currentRenderTemplateIndex = this.currentRenderTemplateIndex + 1;
        this.currentRenderTemplate =
          this.templateData[this.currentRenderTemplateIndex];
        if (this.getWorkflowSavingData && this.getWorkflowSavingData.length) {
          this.matchingTemplate = this.getWorkflowSavingData.find(
            (template) => template.templateID === this.currentRenderTemplate.id
          );
        }
        if (this.currentRenderTemplateIndex > -1 && tempElements.length) {
          this.setPrimaryData(tempElements);
        }
        let newData = {
          elements: tempElements,
          workflowDataId: this.$route.params.workflow_data_id,
          templateID: this.currentRenderTemplate.id,
          name: this.currentRenderTemplate.name,
        };
        let existingWorkflowDataIndex = (
          this.getWorkflowSavingData || []
        ).findIndex((item) => item.workflowDataId === newData.workflowDataId);
        if (existingWorkflowDataIndex === -1) {
          let existingIndex = (this.dataSavingWhileBack || []).findIndex(
            (item) => item.templateID === newData.templateID
          );
          if (existingIndex !== -1) {
            this.dataSavingWhileBack[existingIndex] = newData;
          } else {
            this.dataSavingWhileBack.push(newData);
          }
        }
        await this.checkGenerateCkEditorToPdf();
        if(!this.isEditorCkContentReady){
        this.$store.dispatch(
          "templateWorkflow/updateWorkflowSavingData",
          this.dataSavingWhileBack
        );

        let allUrls =
          this.currentRenderTemplate.document_upload_urls &&
          this.currentRenderTemplate.document_upload_urls.length
            ? this.currentRenderTemplate.document_upload_urls
            : [this.currentRenderTemplate.document_upload_url];
        this.allPdfData = [];
        let relatedTemp = this.allUsersData.find((el) => {
          if (this.currentRenderTemplate.id == el._id) {
            return el;
          }
        });
        let userValues = {};
        let userEntityDataIds = {};
        this.currentDocumentUsers = [];
        if (relatedTemp && relatedTemp.document_users) {
          this.currentDocumentUsers = relatedTemp.document_users;
          relatedTemp.document_users.forEach((user) => {
            if (user.value && user.email) {
              userValues[user.value] = user.email;
              userEntityDataIds[user.value] =
                user.entity_data_id && user.entity_data_id._id
                  ? user.entity_data_id._id
                  : user.entity_data_id;
            }
          });
        }
        let filteredData = this.currentDocumentUsers.filter(
          (user) =>
            user.type !== "SENDER" &&
            !user.constant_user &&
            user.user_type !== "CC"
        );
        if (filteredData && !filteredData.length) {
          this.isCCuserOrSender = true;
        } else {
          this.isCCuserOrSender = false;
        }
        this.total_documents = 1;
        this.total_documents = allUrls.length;
        this.docPages = 0;
        this.scale = this.currentRenderTemplate.scale
          ? this.currentRenderTemplate.scale
          : 0;
        await this.renderAllDocuments(allUrls, true);
        //this.elements = [];
        this.currentRenderTemplate.elements.forEach((el) => {
          if (el.type == "PRIMARY_FIELDS" && userValues[el.selected_user]) {
            if (
              this.userPrimaryData[userValues[el.selected_user]] &&
              this.userPrimaryData[userValues[el.selected_user]][
                el.selected_tag
              ]
            ) {
              el.content =
                this.userPrimaryData[userValues[el.selected_user]][
                  el.selected_tag
                ];
            } else {
              let obj = {};
              obj[el.selected_tag] = el.content ? el.content : "";
              this.userPrimaryData[userValues[el.selected_user]] = {
                ...this.userPrimaryData[userValues[el.selected_user]],
                ...obj,
              };
            }
          } else if (
            el.field_type == "ENTITY_FIELD" &&
            el.template_id &&
            userEntityDataIds[el.selected_user] &&
            this.userEntityData[userEntityDataIds[el.selected_user]] &&
            this.userEntityData[userEntityDataIds[el.selected_user]][
              el.template_id
            ]
          ) {
            let key = el.key;
            if (el.type != "REPEATABLE_PARENT") {
              if (el.indexKey) {
                key = key.split("-")[2];
              } else {
                key = key.split("-")[1];
              }
              if (key && key.includes("#")) {
                key = key.split("#")[0];
              }
            }
            let data = this.setTemplateFieldValue(
              this.userEntityData[userEntityDataIds[el.selected_user]][
                el.template_id
              ],
              key,
              el.indexKey
            );
            if (el.type == "ENTITY") {
              el.entity_data_id = data ? data : el.entity_data_id;
            } else {
              el.value = data ? data : el.value;
            }
          }
          this.elements.push(el);
        });
      }
      }
      if (
        this.matchingTemplate &&
        this.matchingTemplate.elements &&
        this.matchingTemplate.elements.length
      ) {
        this.elements = this.elements.map((element) => {
          const matchingElement = (this.matchingTemplate.elements || []).find(
            (matchingElement) => {
              return element.key === matchingElement.key;
            }
          );
          return matchingElement || element;
        });
      }
    
      //let mappedElements = this.matchingTemplate?.elements ? this.matchingTemplate?.elements : []
      this.loading = false;
    },
    async renderPreviousTemplate() {
      this.loading = true;
      if (this.templateData && this.templateData.length) {
        if (
          this.currentRenderTemplateIndex < this.templateData.length &&
          this.elements.length
        ) {
          this.setPrimaryData(this.elements);
        }
        this.elements = [];
        this.currentRenderTemplateIndex =
          this.currentRenderTemplateIndex == 0
            ? this.templateData.length - 1
            : this.currentRenderTemplateIndex - 1;
        this.currentRenderTemplate =
          this.templateData[this.currentRenderTemplateIndex];
          await this.checkGenerateCkEditorToPdf();
        if(!this.isEditorCkContentReady){
        let allUrls =
          this.currentRenderTemplate.document_upload_urls &&
          this.currentRenderTemplate.document_upload_urls.length
            ? this.currentRenderTemplate.document_upload_urls
            : [this.currentRenderTemplate.document_upload_url];
        this.allPdfData = [];
        (this.total_documents = 1), (this.docPages = 0);
        let relatedTemp = this.allUsersData.find((el) => {
          if (
            this.currentRenderTemplate &&
            this.currentRenderTemplate.id == el._id
          ) {
            return el;
          }
        });
        let userValues = {};
        let userEntityDataIds = {};
        this.currentDocumentUsers = [];
        if (relatedTemp && relatedTemp.document_users) {
          this.currentDocumentUsers = relatedTemp.document_users;
          relatedTemp.document_users.forEach((user) => {
            if (user.value && user.email) {
              userValues[user.value] = user.email;
              userEntityDataIds[user.value] =
                user.entity_data_id && user.entity_data_id._id
                  ? user.entity_data_id._id
                  : user.entity_data_id;
            }
          });
        }
        this.scale = this.currentRenderTemplate.scale
          ? this.currentRenderTemplate.scale
          : 0;
        await this.renderAllDocuments(allUrls, true);
        this.currentRenderTemplate.elements.forEach((el) => {
          if (
            el.type == "PRIMARY_FIELDS" &&
            userValues[el.selected_user] &&
            this.userPrimaryData[userValues[el.selected_user]] &&
            this.userPrimaryData[userValues[el.selected_user]][el.selected_tag]
          ) {
            el.content =
              this.userPrimaryData[userValues[el.selected_user]][
                el.selected_tag
              ];
          } else if (
            el.field_type == "ENTITY_FIELD" &&
            el.template_id &&
            userEntityDataIds[el.selected_user] &&
            this.userEntityData[userEntityDataIds[el.selected_user]] &&
            this.userEntityData[userEntityDataIds[el.selected_user]][
              el.template_id
            ]
          ) {
            let key = el.key;
            if (el.type != "REPEATABLE_PARENT") {
              if (el.indexKey) {
                key = key.split("-")[2];
              } else {
                key = key.split("-")[1];
              }
              if (key && key.includes("#")) {
                key = key.split("#")[0];
              }
            }
            let data = this.setTemplateFieldValue(
              this.userEntityData[userEntityDataIds[el.selected_user]][
                el.template_id
              ],
              key,
              el.indexKey
            );
            if (el.type == "ENTITY") {
              el.entity_data_id = data ? data : "";
            } else {
              el.value = data ? data : "";
            }
          }
          this.elements.push(el);
        });
      }
      }
      this.loading = false;
    },
    // async renderPreviewPages(url) {
    //   const self = this;
    //   function renderPage(page) {
    //     let scale = 1;
    //     var viewport = page.getViewport({ scale: scale });
    //     let width = viewport.width;
    //     if (self.docPages < width) {
    //       self.docPages = width;
    //     }
    //   }
    //   function renderPages(pdfDoc) {
    //     for (var num = 1; num <= pdfDoc.numPages; num++) {
    //       if (num == pdfDoc.numPages) {
    //         pdfDoc
    //           .getPage(num)
    //           .then(renderPage)
    //           .then(
    //             setTimeout(() => {
    //               self.getWidthandHeight(
    //                 url,
    //                 document.getElementById("wrapper")
    //               );
    //             }, 1000)
    //           );
    //       } else {
    //         pdfDoc.getPage(num).then(renderPage);
    //       }
    //     }
    //   }

    //   pdfjsLib.disableWorker = true;
    //   let random = (Math.random() + 1).toString(36).substring(7);
    //   url += `?view=${random}`;
    //   pdfjsLib.getDocument(url).then(this.getPages).then(renderPages);
    // },
    async renderPreviewPages(id) {
      const self = this;
      let i = id;
      if (i == 0) {
        this.docPages = 0;
      }
      async function renderPage(page) {
        let scale = 1;
        var viewport = page.getViewport({ scale: scale });
        let width = viewport.width;
        if (self.docPages < width) {
          self.docPages = width;
        }
      }
      if (
        this.allPdfData &&
        this.allPdfData.length &&
        this.allPdfData[i] &&
        this.allPdfData[i].pages
      ) {
        for (var num = 1; num <= this.allPdfData[i].pages; num++) {
          if (
            num == this.allPdfData[i].pages &&
            i + 1 == this.total_documents
          ) {
            await this.allPdfData[i].pdfDoc
              .getPage(num)
              .then(renderPage)
              .then(
                setTimeout(() => {
                  self.allPdfData.forEach(async (doc, i) => {
                    await self.getWidthandHeight(i);
                  });
                }, 500)
              );
          } else {
            await this.allPdfData[i].pdfDoc.getPage(num).then(renderPage);
          }
        }
      }
    },
    getPages(pdfDoc) {
      this.pdfPages = pdfDoc.numPages;
      this.pdfData = pdfDoc;
      return pdfDoc;
    },
    async getWidthandHeight(id) {
      const self = this;
      let scale = 1.6;
      let i = id;
      for (let i = 0.5; i <= 2; i += 0.1) {
        if (self.docPages && self.docPages * i <= 955) {
          scale = i;
        }
      }
      if (this.scale == 0) {
        this.scale = scale;
        // this.updateConfigDocumentScale();
      } else {
        scale = this.scale;
      }
      self.scale = scale;
      function renderPage(page) {
        var viewport = page.getViewport({ scale: scale });
        var canvas = document.getElementById(`${i}_canvas_${page.pageNumber}`);
        canvas.style.cssText =
          "box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);";
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        canvas.height = viewport.height;
        canvas.width = viewport.width - 2;
        page.render(renderContext);
        self.pageWidth = viewport.width;
        self.pageHeight += viewport.height;
      }
      for (var num = 1; num <= this.allPdfData[i].pages; num++) {
        this.allPdfData[i].pdfDoc.getPage(num).then(renderPage);
      }
    },
    //render all documents
    async renderAllDocuments(document_url_paths, isNew) {
      for (let i = 0; i < document_url_paths.length; i++) {
        let url = document_url_paths[i];
        await this.setPdfData(url, i, isNew);
      }
    },
    async setPdfData(url, id, isNew) {
      const self = this;
      let i = id;
      console.log({ isNew });

      await pdfjsLib.getDocument(url).then(setPDfData);
      async function setPDfData(pdfDoc) {
        self.allPdfData.push({
          pdfDoc: pdfDoc,
          pages: pdfDoc.numPages,
          order: i,
        });
        if (self.total_documents == i + 1) {
          self.allPdfData = self.allPdfData.sort((a, b) => a.order - b.order);
          await self.allPdfData.forEach(async (doc, index) => {
            await self.renderPreviewPages(index);
          });
        }
      }
    },
  },
  watch: {
    is_prepare() {
      this.prepareTempData();
    },
    elements: {
      handler: function () {
        bus.$emit("formula-updated");
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.$store.commit("templatesData/setTemplatesData", null, { root: true });
  },
};
</script>
<style lang="scss">
#drag-template-background {
  .view-workflow-document {
    .document-root {
      .form-holder {
        .form-fields-holder {
          .el-input__prefix {
            display: none;
          }
          .el-input__inner,
          .el-textarea__inner {
            // height: 100% !important;
            // min-height: 30px !important;
            line-height: inherit !important;
          }
          .el-input {
            input {
              height: 100%;
              border: none;
              padding: 5px;
            }
          }
          .el-textarea {
            textarea {
              height: 100%;
              border: none;
              padding: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.document-status {
  position: fixed;
  bottom: 0px;
  background: white;
  border: #ccc 1px solid;
  height: 70px;
  width: 950px;
  @media (min-width: 1300px) {
    &.builder-default {
      width: 950px;
    }
  }
  @media (min-width: 320px) {
    max-width: 950px;
  }
}
.view-workflow-document {
  padding-top: 0 !important;
  background-color: #f8f9fa;
  min-height: 100vh;
  margin-top: 70px;
  .template-grand-parent {
    .template-container-wrapper-parent {
      .document-root {
        position: relative;
        width: 950px;
        border-radius: 10px;
        margin: 10px auto 0;
        @media (min-width: 1300px) {
          &.builder-default {
            width: 950px;
          }
        }
        @media (min-width: 320px) {
          max-width: 950px;
        }
        .form-field-options {
          position: sticky;
          bottom: 0px;
          margin: 10px auto 0;
          padding: 8px 10px;
          background-color: #ffffff;
          border: 1px solid #efefef;
          border-radius: 5px;
        }
        .each-stat {
          margin-right: 1em;
        }
        .field-stats-block {
          p {
            line-height: 30px;
            &.text {
              color: #333333;
              font-weight: 400;
            }
          }
          .count {
            margin-right: 0.5em;
            background-color: #ffffff;
            height: 30px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff !important;
            font-weight: 600;
            font-size: 1.1em;
            border-radius: 3px;
            &.completed {
              background-color: #027e02;
              color: #ffffff !important;
            }
            &.in-progress {
              background-color: #0101ca;
              color: #ffffff !important;
            }
          }
        }
        .form-builder {
          width: 100%;
          max-height: 100%;
          background-color: #ffffff;
          border: 1px solid #efefef;
          -webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
          box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
          border-radius: 10px;
          .form-image-holder {
            margin-bottom: 8%;
            width: 100%;
            img {
              width: 100%;
            }
            div {
              width: 100%;
              height: 100%;
            }
          }

          .form-fields-holder {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            .each-element img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
#drag-template-background {
  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
  }
  .img-fluid {
    height: auto !important;
  }
  .inner-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #ffffff;
    border: 1px solid #efefef;
    margin-bottom: 0 !important;
    & > .d-flex {
      gap: 10px;
      .back-btn {
        height: inherit;
      }
    }
    .left-block {
      .el-input__inner {
        min-width: 250px;
        max-width: 300px;
        background: #fff;
        height: 47px;
      }
    }
    .el-dropdown-link {
      cursor: pointer;
      border: 1px solid #dcdfe6;
      border-radius: 2px;
      padding: 7px 14px;
      font-weight: 600;
      font-size: 0.91em;
    }
    .expiry-date {
      border-radius: 20px;
      padding: 0px 10px;
      background: #f754a2;
      color: #ffffff;
      font-weight: 600;
      font-size: 0.91em;
      border-color: #f754a2;
    }

    .user-tag {
      border-radius: 20px;
      padding: 4px;
      line-height: 1;
      height: 30px;
      width: 30px;
      height: auto;
      margin-left: -0.2em;
    }
    .manage-recipients {
      padding: 7px 14px;
      border: none;
      .el-tag {
        border-radius: 20px;
        padding: 4px;
        line-height: 1;
        height: 30px;
        width: 30px;
        height: auto;
        margin-left: -0.2em;
      }
    }
  }

  .navbar-status {
    // padding-right: 10px;
    // padding-left: 10px;
    position: fixed;
    border: 1px solid #dcdfe6;
    position: relative;
  }
  .document-preview-block {
    position: relative;
    @media (min-width: 992px) {
      position: fixed;
    }
    top: 64px;
    display: grid;
    &.singleColumn {
      justify-content: center;
    }

    &.twoColumn {
      grid-template-columns: 1fr 340px;
    }
    // justify-items: space-between;
    // justify-items: center;
    .activity-block {
      background-color: #ffffff;
      // border-left: 1px solid #efefef;
      // Tags
      .el-tabs {
        box-shadow: none;
        min-height: calc(100vh - 62px);
      }
    }
    td {
      padding-top: 0;
      vertical-align: top;
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 80%;
      }
    }
    .inputs-list {
      background: #fff;
      padding: 15px;
      box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
        0 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      .el-button {
        border: none;
        background: rgba(236, 245, 255, 0.7);
        &:hover {
          background: rgba(236, 245, 255, 1);
        }
      }
      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
  .form-image {
    max-width: 770px;
    min-height: 1000px;
    background-color: #e6dcdc;
  }
  .el-input__inner {
    display: inline !important;
  }

  .form-holder {
    width: 100%;
    position: relative;
    .el-input__inner,
    .el-textarea__inner {
      height: 100% !important;
      // min-height: 30px !important;
      line-height: inherit !important;
    }
    input[type="checkbox"] {
      margin: 0 !important;
    }
  }

  .sample-form1 {
    position: relative;
    width: 100%;
    max-height: 91vh;
    overflow: scroll;
    border: 1px solid #ccc;
    margin-left: 20px;
    margin-bottom: 45px;

    ///
    .each-page {
      position: relative;
      width: 900px;
      display: block;
      height: 800px;
      overflow-y: auto;
      .form-image {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
      }
      .form-fields {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
        z-index: 100;
      }
      .page-image {
        width: 850px;
        margin: 0 auto;
      }
    }
  }

  .color-btn {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;
    &.red {
      background-color: red;
    }
    &.black {
      background-color: black;
    }

    &.green {
      background-color: green;
    }
  }
  .tooltip {
    padding: 0 !important;
  }
  // Fields
  .form-fields-set {
    background-color: #ffffff;
    padding: 1.25em 1em;
    border-radius: 2px;
    -webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
    .el-form-item {
      margin-bottom: 0.5em;
    }
  }
}
.el-date-editor {
  .el-input__prefix {
    display: none !important;
  }
}
.form-holder {
  .el-textarea {
    &.textarea-nowrap {
      textarea {
        white-space: nowrap !important;
      }
    }
  }
}
.form-fields-holder {
  .el-input,
  .el-select {
    height: inherit;
    width: inherit;
    .el-input__inner {
      height: inherit;
      width: inherit;
    }
  }
  .el-date-editor {
    &.date-picker {
      input.el-input__inner {
        text-align: center !important;
      }
      .el-input__prefix {
        display: none;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-icon-circle-close {
            display: inherit;
            line-height: 1 !important;
          }
        }
      }
    }
  }
  .date-signed {
    .el-input__prefix {
      display: none;
    }
  }
  textarea {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
  .el-input {
    input {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }
}
.dialog-initial {
  .el-dialog {
    min-width: 320px;
  }
}
.document-container {
  position: relative;
  .el-scrollbar {
    height: calc(100vh - 65px);
  }
  .el-scrollbar__thumb {
    width: 20px;
  }
  .el-input__inner {
    padding: 0 !important;
  }
}
.view-configure-document {
  padding-top: 0 !important;
  background-color: #f8f9fa;
  min-height: 100vh;
  .field-identifer {
    position: absolute;
    top: 0;
    left: 2px;
    p {
      color: #ffffff;
      position: relative;
      top: -30px;
      left: 10px;
    }
    img {
      border-radius: 3px;
    }
  }
  .field-identifer-mobile {
    position: absolute;
    top: 0;
    left: 2px;
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    p {
      color: #ffffff;
      position: relative;
      top: -30px;
      left: 10px;
    }
    img {
      border-radius: 3px;
    }
  }
  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    65% {
      transform: translate(0, 15px);
    }
    to {
      transform: translate(0, -0px);
    }
  }
  .document-title {
    line-height: 62px;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 0;
  }
  .form-title {
    .el-input {
      position: relative;
      input {
        padding: 0;
        line-height: 1;
        background-color: rgba(255, 255, 255, 0.35);
        border: none;
        font-size: 1.5em;
        height: 60px !important;
        font-weight: 400;
        letter-spacing: -0.5px;
        margin-bottom: 0;
        color: #222222;
        box-shadow: none;
      }
      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 20px;
        width: 10px;
        //background-image: url(/img/hover-dots.ecfb2fa2.svg);
        background-size: 10px 20px;
        background-repeat: no-repeat;
        background-position: center;
        left: 0px;
        opacity: 0;
        z-index: 10;
        transition: 0.3s all ease-in-out;
        -webkit-transition: 0.3s all ease-in-out;
      }
    }
  }
  .form-builder {
    // .req-item {
    //   button {
    //     &:focus {
    //       outline: 2px solid rgb(21, 101, 216) !important;
    //     }
    //   }
    // }
    .el-input,
    .el-select {
      height: inherit;
      width: inherit;
      .el-input__inner {
        height: inherit;
        width: inherit;
      }
    }
    // .field-focused {
    //   outline: 2px solid rgb(21, 101, 216) !important;
    //   box-shadow: 0 4px 8px 0 rgb(21, 101, 216),
    //     0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // }
    // .default {
    //   outline: 2px solid rgb(21, 101, 216) !important;
    // }
    // .completed {
    //   outline: 2px solid rgb(83, 216, 21) !important;
    // }
    // .active {
    //   outline: 2px solid rgb(21, 101, 216) !important;
    //   box-shadow: 0 4px 8px 0 rgb(21, 101, 216),
    //     0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // }
  }
  .activity-block {
    .el-tabs__content {
      padding: 0;
    }
    .el-tabs--border-card {
      .el-tabs__header {
        .el-tabs__item {
          &.is-active {
            color: #f15178;
          }
        }
      }
    }
  }

  .right-block {
    height: inherit;
    display: flex;
    align-items: center;
  }
}
.req-item {
  .el-textarea {
    textarea {
      height: 100% !important;
    }
  }
}
.el-message-box {
  @media (max-width: 575.98px) {
    width: 95% !important;
  }
}
.content-fields {
  min-width: 15px !important;
  min-height: 15px !important;
  .field-textarea {
    &.textarea-nowrap {
      textarea {
        white-space: nowrap !important;
      }
    }
    height: 100% !important;
    &.el-textarea {
      vertical-align: baseline !important;
    }
  }
  .overlay {
    overflow: hidden;
    white-space: nowrap;
    width: 82% !important;
  }
  // position:relative; // for tooltip
  &[data-text*="DATE "] {
    background: none !important;
  }
  &:before {
    content: attr(data-text);
    position: absolute;
    top: -25px;
    -webkit-transform: translateY(-50%);
    left: -17px;
    margin-left: 15px;
    width: 142px;
    height: 10px;
    padding: 10px;
    border-radius: 9px;
    background: #555;
    color: white;
    text-align: center;
    line-height: 10px;
    visibility: hidden;
    opacity: 0; /* define initial transition property */
    transition: opacity 1s;
  }
  &:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 47px;
    margin-left: -11px;
    border-width: 9px 9px 10px 0px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    visibility: hidden;
    opacity: 0; /* define initial transition property */
    transition: opacity 1s;
  }
  &:hover:before {
    visibility: visible;
    opacity: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }
  &:focus-visible {
    outline: none !important;
  }
  &.el-textarea {
    width: 100% !important;
    height: 100% !important;
  }
  padding: 0 !important;
  &.my-active-class {
    border-width: 2px !important;
    background: transparent !important;
    .overlay {
      display: none;
    }
  }
  &[has-value="1"] {
    background: transparent !important;
  }
  &[has-content="1"] {
    background: transparent !important;
  }
  &[has-background="0"] {
    background: transparent !important;
  }
  &[is-border-none="1"] {
    border: none !important;
  }
  & > p {
    &:last-child {
      margin: 12px 7px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  & > div {
    &:last-child {
      width: 100% !important;
      height: 100% !important;
      background: none;
      textarea.el-textarea__inner {
        padding: 0px 5px;
        width: 100% !important;
        height: 100% !important;
        border: none !important;
        background: transparent !important;
        resize: none !important;
        overflow: hidden;
        color: inherit;
      }
      .overlay {
        position: absolute;
        left: 5px !important;
        top: 0px !important;
        width: 100%;
        height: 100%;
        z-index: 5;
        padding: 0px !important;
      }
      .el-select {
        width: 100% !important;
        height: 100% !important;
        .el-input {
          width: 100% !important;
          height: 100% !important;
          font: inherit;
          .el-input__inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
        .el-input__suffix-inner {
          i {
            line-height: 100% !important;
          }
        }
        .el-tag {
          height: 100%;
          font: inherit;
          // .el-select__tags-text {
          //   font: inherit;
          // }
        }
      }
      .el-date-editor {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: row-reverse;
        font: inherit;
        background: none;
        .el-input__inner {
          width: 100%;
          height: 100%;
          font: inherit;
          border: none;
        }
        .el-input__prefix {
          position: relative;
          i {
            line-height: 100%;
            display: inline !important;
          }
        }
      }
      input[type="checkbox"] {
        width: 100%;
        height: 100%;
      }
      &.yes-or-no {
        // padding-left: 5px;
        .el-radio {
          font: inherit !important;
          .el-radio__label {
            font: inherit !important;
          }
          &:first-child {
            margin-right: 10px !important;
          }
          &:last-child {
          }
        }
      }
      .primary-field {
        text-area.el-textarea__inner {
          background: transparent !important;
        }
      }
      &.image-field {
        // padding: 3px 12px;
        color: inherit;
        position: absolute;
        left: 5px !important;
        top: 5px !important;
        padding: 0px !important;
        width: 80% !important;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  & > img {
    &.my-signature {
      background: transparent !important;
      width: 100%;
      // height: 100%;
      height: auto;
      visibility: visible !important;
    }
    &.my-initial {
      background: transparent !important;
      width: 100%;
      // height: 100%;
      height: auto;
    }
  }
}
</style>
<style lang="scss" scoped>
#drag-template-background {
  .view-workflow-document {
    .document-root {
      .form-holder {
        .form-fields-holder {
          .el-input {
            input {
              height: 100%;
              // border: none;
              border: 1px solid #e4e7ed;
              // padding: 5px;
            }
          }
          .el-textarea {
            textarea {
              height: 100%;
              // border: none;
              border: 1px solid #e4e7ed;
              // padding: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border: 1px solid #999;
  border-radius: 4px;
  position: relative;
  background: none;
}

.custom-checkbox:checked::before {
  content: "\00D7"; /* Cross mark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* //font-size: 32px; */
  color: black;
  background: none;
}
</style>
<style scoped>
.input-container {
  display: flex;
}

.box {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin-right: -1px;
  border-top: 1px solid black;
}

.small-box {
  width: 20px;
  height: 20px;
  border-top: 1px solid black;
}

.box-input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  text-align: center;
  border: none;
  outline: none;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
</style>
